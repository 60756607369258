import lib from 'lib';

var editorTooltipData = {
  scribe: {
    title: 'Scribe',
    body: `Scribe is the flex material beween our units and perpenicular walls, ceiling, or product. This
    material can be cut down onsite by the installer to fit in place.`,
    imageUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/design-engine/images/space-theory/Base+Scribe+2.jpg'
  },
  kickHeight: {
    title: 'Kick Height',
    body: `Kick is the material and space between the unit and the floor. This space allows your feet to rest
    comfortably under the cabinet while working in the kitchen. Typical kick heights are 4 or 4 1/2"`,
    imageUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/design-engine/images/space-theory/Kick+2.jpg'
  },
  flushKick: {
    title: 'Flush Kick',
    body: `A detail condition where kick is flush with face of fronts. This is typically used at back sides
    of island and where tall storage is to feel built into a wall area where you will not be working or
    accessing units freqently. `
  },
  container: {
    title: 'Container',
    body: `Houses available products inside of them. Use to layout your space schematically then add products
    to create the specific layout for your kitchen.`
  },
  insetKick: {
    title: 'Inset Kick',
    body: `To be used when the end panel does not extend to the ground and kick wraps unit to extend back to
    the wall or unit adjacent.`,
    imageUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/design-engine/images/space-theory/Kick+1.jpg'
  },
  flybyFronts: {
    title: 'Flyby Fronts',
    body: `A detail condition where drawer or door front extends past the unit to capture an end panel. Can
    only be used on doors when the panel is opposite the hinge.`,
    imageUrl: 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/design-engine/images/space-theory/Base+End+Panel+2.jpg'
  },
  lockCountertops: {
    title: 'Lock Countertops',
    body: `Turn on in plan view to lock countertops with base units below. This allows you to move countertops
    and base containers as one. Turn off to manually adjust countertops.`
  },
  precision: {
    title: 'Precision',
    body: `The amount of precision when scaling an element - container, product, wall, architectural element.
    Available at 4", 2", 1",  1/2", 1/4" and 1/8" increments.`
  }
};

var K = {
  defaultFontSize: 11 / 3,
  editorTooltipData,
  sideSizeMap: {
    front: {width: 'width', height: 'height'},
    back: {width: 'width', height: 'height'},
    left: {width: 'depth', height: 'height'},
    right: {width: 'depth', height: 'height'},
    top: {width: 'width', height: 'depth'},
    bottom: {width: 'width', height: 'depth'}
  },
  //css
  margin: 5,
  spacing: 10,
  shadow: {boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'},
  paneWidth: 250,
  borderRadius: 5,
  defaultIconSize: {width: 20, height: 20},
  //WARNING gets set to libre franklin for HB
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  fonts: {
    label: {fontSize: '0.9em', letterSpacing: '1px', fontWeight: 'normal', opacity: 0.5, textTransform: 'uppercase'},
    header: {fontSize: '1.2rem', letterSpacing: '1px', fontWeight: 'bold'}
  },
  grayBorder: '1px solid rgba(0, 0, 0, 0.1)',
  uploadedFilesUrlPrefix: 'https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files',
  cdnUrlPrefix: 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn',
  drawingsStrokeWidths: {
    heavy: 1.5,
    medium: 1,
    light: 0.5
  },

  //product
  wallUnitDepth: 25.125,
  precision: 1 / 8,
  minPrecision: 1 / 16,
  maxPrecision: 4,
  productPrecision: 1 / 8,
  productSizePrecision: 1 / 4,
  defaultWallHeight: 120,
  scribeThreshold: 8,
  nearbyElementForCountertopThreshold: 24,
  countertopOverhang: 1 / 4,
  endPanelThickness: 1 / 2,
  sinkMargins: {sides: 2, front: 3, back: 2},
  sinkDrainfieldWidth: 18,
  disabledOpacity: 0.6,
  scribeThickness: 0.75,
  nearbyContainerThreshold: 24,
  nearbyWallThreshold: 24,
  colors: {
    bad: '#ce6f6f',
    good: '#80b963',
    selected: 'rgb(145, 203, 234)',
    stencil: 'rgba(234, 200, 157)',
    invalid: '#ED7B84',
    datums: '#ab9002',
    customDimensions: '#fa8546',
    elevationStroke: '#479F6A',
    lighting: '#FFD800',
    hatches: '#888888',
    base: '#ffffff',
    active: '#F5F5F5',
    gray: '#F5F5F5',
    white: '#FFFFFF',
    black: '#000000'
  },
  designPlanningCompleteColorMap: {
    assemblies: '8B9AA4',
    backsplash: 'DDDCDD',
    barBlock: 'AFBEB2',
    countertop: 'eeeeee',
    horizontalBarblock: 'AFBEB2',
    valet: 'AFBEB2',
    base: 'DCD6C5',
    vanity: 'DCD6C5',
    baseWithChase: 'DCD6C5',
    cornerCounterTransition: 'DCD6C5',
    opencase: 'EEEEEF',
    floatingBase: 'F2EFDE',
    floatingShelves: '6F6554',
    islandSeating: 'BAB0A6',
    islandBackPanel: 'BAB0A6',
    islandExtension: 'BAB0A6',
    hbIslandExtension: 'BAB0A6',
    tall: '8C8D8C',
    pivotDoor: '8C8D8C',
    wall: 'D4D9DC',
    wallPanels: 'D4D9DC',
    freestandingAppliance: 'AFAB9F'
  },
  roundSTProduct: value => {
    if (typeof(value) === 'object') {
      return lib.object.round(value, {toNearest: 1 / 4});
    }
    else {
      return lib.number.round(value, {toNearest: 1 / 4});
    }
  },
  round: value => {
    if (typeof(value) === 'object') {
      return lib.object.round(value, {toNearest: K.minPrecision});
    }
    else {
      return lib.number.round(value, {toNearest: K.minPrecision});
    }
  },
  appearancePropsByTheme: () => ({
    stroke: {light: '#333333', dark: 'white'}
  }),
  filledAppearancePropsByTheme: () => ({
    stroke: {light: '#333333', dark: 'white'},
    fill: {light: '#f0f0f0', dark: 'rgba(255, 255, 255, 0.05)'}
  }),
  containerAppearancePropsByTheme: () => ({
    ...K.appearancePropsByTheme(), fill: {dark: 'rgba(0, 0, 0, 0.8)', light: 'rgb(204, 204, 204)'}
  }),
  sectionContainerAppearancePropsByTheme: () => ({
    ...K.appearancePropsByTheme(), fill: {dark: 'rgba(0, 0, 0, 0.8)', light: 'rgb(120, 120, 120)'}
  }),
  archAppearancePropsByTheme: () => ({
    stroke: {light: '#333333', dark: 'rgba(200, 200, 200)'},
    fill: {light: '#333333', dark: 'rgb(70, 70, 70)'}
  }),
  componentProductAppearancePropsByTheme: () => ({
    ...K.appearancePropsByTheme(), fill: {light: '#9A9A9A', dark: 'white'}
  }),
  opencaseRodAppearancePropsByTheme: () => ({
    ...K.appearancePropsByTheme(), fill: {light: '#333333', dark: 'white'}
  }),
  applianceAppearancePropsByTheme: () => ({
    ...K.appearancePropsByTheme(), fill: {dark: 'rgba(255, 255, 255, 0.2)', light: 'rgb(156, 156, 156)'}
  }),
  frameAndPanelCompatibleFrontMaterials: [1, 8, 154, 158, 358, 72],
  hb: {
    title: 'Henrybuilt',
    ids: {
      tallCustomApplianceFrameProductIds: [
        1234, 1235, 1239, 1240, 1277, 1278, 121, 122, 123, 124, 125, 126
      ],
      islandEndPanel: {
        productIdDepthMap: {0.25: 1287, 0.375: 1289, 0.5: 1288, 0.75: 1290, 1.5: 1443, 1.625: 1444, 2.125: 1445, 4: 1378}
      },
      endPanel: {
        productIdDepthMap: {0.25: 1198, 0.375: 1199, 0.5: 1200, 0.75: 1201, 1.5: 1440, 1.625: 1441, 2.125: 1442, 4: 1378},
        trimPanelProductHeightMap: {0.25: 1691, 0.375: 1689, 0.5: 1687, 0.75: 1526, 1.5: 1682}
      },
      capPanel: {
        //TODO handle 2 1/4"
        productIdHeightMap: {0.25: 1202, 0.375: 1203, 0.5: 1204, 0.75: 1205, 1.5: 1448, 2.25: 1448},
        trimPanelProductHeightMap: {0.25: 1692, 0.375: 1690, 0.5: 1688, 0.75: 1639, 1.5: 1681}
      },
      countertop: {
        defaultProductId: 361,
        productIdHeightMap: {361: 0.5, 374: 0.75, 360: 0.5, 363: 1, 1181: 1.5, 362: 1.5, 1180: 2.5}
      },
      kick: {
        defaultProductId: 1451,
        flush: 1523
      },
      seatingSupportRod: {
        defaultProductId: 1286
      },
      islandExtensionFrame: {
        defaultProductId: 191
      },
      scribe: {
        defaultProductId: 1452,
        productIdTypeMap: {base: 1453, floatingBase: 1454, wall: 1454, recessed: 1455, panel: 1465}
      },
      islandBackPanel: {
        defaultProductId: 1291
      },
      managedSculptedPanels: {
        productIdSculptedTypeMap: {coved: 1456, singleGroove: 1457, doubleGroove: 1458, chamfered: 1459, fluted: 1460}
      },
      hiddenPanels: [1439, 1479, 1480, 1481],
      verticalHiddenPanels: [1439, 1479, 1480],
      horizontalHiddenPanels: [1481, 1533],
      notchedWallPanels: [1494],
      sculptedPanels: [1456, 1457, 1458, 1459, 1460, 1593, 1594, 1595, 1596, 1597, 1603, 1604, 1605, 1606, 1607],
      sculptedEndPanels: [1593, 1594, 1595, 1596, 1597, 1603, 1604, 1605, 1606, 1607],
      horizontalSculptedEndPanels: [1593, 1594, 1595, 1596, 1597],
      verticalSculptedEndPanels: [1603, 1604, 1605, 1606, 1607],
    },
    fieldOptions: {
      countertop: {
        productId: [
          {value: 361, title: '½" Paperstone Onyx'},
          {value: 374, title: '¾" Paperstone Onyx'},
          {value: 360, title: '½" Solid Surface Acrylic'},
          {value: 363, title: '1" Solid Wood, Plank'},
          {value: 1181, title: '1 ½" Solid Wood, Plank'},
          {value: 362, title: '1 ½" Solid Wood, Butcher Block'},
          {value: 1180, title: '2 ½" Solid Wood, Butcher Block (only freestanding)'}
        ]
      }
    },
    fields: {
      kick: [{
        key: 'customData.kickHeight',
        title: 'Kick Height',
        type: 'size',
        precision: 1 / 8,
        minValue: 0,
        views: ['front'],
        tooltipData: editorTooltipData.kickHeight
      }, {
        key: 'customData.flushKick',
        title: 'Flush Kick',
        type: 'checkbox',
        views: ['front'],
        // options: [{value: 1, title: 'Enabled'}, {value: 0, title: 'Disabled'}],
        tooltipData: editorTooltipData.flushKick
      }]
    },
    materialIds: {
      endPanelVeneer: [1, 3, 4, 5, 6, 8, 9, 11, 12, 79, 80, 81, 82, 83, 84, 128, 129, 130, 132, 147, 156, 157, 158, 191, 192, 239, 240, 251, 301, 302, 358]
    },
    pullDimensions: {
      1: {
        width: 5,
        height: 0.25
      },
      2: {
        width: 6,
        height: .438
      },
      3: {
        width: 8,
        height: 0.5
      },
      4: {
        width: 6,
        height: 1
      },
      5: {
        width: 9,
        height: .625
      },
      6: {
        radius: 0.6875
      },
      7: {
        width: 6,
        height: 0.625
      },
      8: {
        width: 3.5,
        height: 0.5
      },
      9: {
        width: 5.785,
        height: 1
      },
      10: {
        height: .1875,
        width: 6
      },
      11: {
        width: 2.25,
        height: .1875
      },
      12: {
        width: 7,
        height: 13 / 16
      },
      13: {
        width: '100%',
        height: 5 / 8
      },
      14: {
        width: '100%',
        height: 3 / 4
      },
      15: {
        width: 4,
        height: 1.5
      },
      16: {
        radius: 1.188
      },
      17: {
        width: 6.1875,
        height: 1.09375
      },
      //18 pull by owner
      19: {

      },
      20: {
        width: 3.5,
        height: .438
      },
      //21 no pulls
      //22 touch latch
      //23 scooped bottom pull
      //24 push to open
      //25 pull tbd
      26: {
        width: 10,
        height: 1
      }
    }
  },
  vp: {
    title: 'Space Theory',
    ids: {
      tallCustomApplianceStackProductIds: [],
      islandEndPanel: {
        productIdDepthMap: {0.25: 793, 0.5: 794, 0.75: 795, 1.5: 1169, 3: 1629}
      },
      endPanel: {
        productIdDepthMap: {0.25: 791, 0.5: 790, 0.75: 792, 1.5: 1167, 3: 1629},
        trimPanelProductHeightMap: {0.5: 1685, 0.75: 1582, 1.5: 1684}
      },
      capPanel: {
        productIdHeightMap: {0.25: 796, 0.5: 797, 0.75: 798, 1.5: 1168, 2.25: 799},
        trimPanelProductHeightMap: {0.5: 1686, 0.75: 1640, 1.5: 1683}
      },
      countertop: {
        defaultProductId: 802,
        productIdHeightMap: {802: 0.5, 803: 1.5}
      },
      kick: {
        defaultProductId: 801,
        flush: 738
      },
      seatingSupportRod: {
        defaultProductId: 800
      },
      islandExtensionFrame: {
        defaultProductId: 854
      },
      scribe: {
        defaultProductId: 737,
        productIdTypeMap: {base: 735, floatingBase: 735, wall: 736, recessed: 856, panel: 734}
      },
      islandBackPanel: {
        defaultProductId: 728
      },
      managedSculptedPanels: {
        productIdSculptedTypeMap: {}//{coved: 1456, singleGroove: 1457, doubleGroove: 1458, chamfered: 1459, fluted: 1460}
      },
      hiddenPanels: [1521, 1619],
      verticalHiddenPanels: [1521],
      horizontalHiddenPanels: [1619],
      notchedWallPanels: []
    },
    fieldOptions: {
      countertop: {
        productId: [
          {value: 802, title: '½" Acrylic or Paperstone'},
          {value: 803, title: '1 ½" Solid Wood'}
        ]
      }
    },
    fields: {
      kick: [{
        key: 'customData.kickHeight',
        type: 'select',
        noThumbnail: true,
        title: 'Kick Height',
        views: ['front'],
        options: [
          {value: 0, title: '0'},
          {value: 0.75, title: '3/4'},
          {value: 3.75, title: '3 3/4'},
          {value: 4, title: '4'},
          {value: 4.25, title: '4 1/4'},
          {value: 4.5, title: '4 1/2'},
          {value: 4.75, title: '4 3/4'}
        ],
        tooltipData: editorTooltipData.kickHeight
      }, {
        key: 'customData.flushKick',
        title: 'Flush Kick',
        type: 'checkbox',
        views: ['front'],
        // options: [{value: 1, title: 'Enabled'}, {value: 0, title: 'Disabled'}],
        tooltipData: editorTooltipData.flushKick
      }]
    },
    materialIds: {
      endPanelVeneer: [239, 240, 249, 250, 251, 319, 320, 321, 325, 329, 330, 332]
    },
    pullDimensions: {}
  }
};

K.supportedCountertopContainerTypes = [
  'base',
  'baseWithChase',
  'islandSeating',
  'floatingBase',
  'islandBackPanel',
  'islandExtension',
  'hbIslandExtension',
  'vanity',
  'daylightIsland',
  'sculptedPanel'
];

K.opencaseConstants = {
  spacing: {x: 7.5, y: 6},
  minMargin: 3,
  maxSize: {long: 95, short: 47},
  minSize: {width: 6, height: 6}
};

K.barblockConstants = {
  vertical: {
    shelfHeight: 5.875,
    innerWidth: 4.625,
    topOfFixedShelfYPosition: -30,
    bottomOfFixedShelfYPosition: -29.25
  },
  wrapThickness: 0.75
};

K.barblockComponentConstants = {
  wrapThickness: 0.75,
  horizontalBarblockDividerThickness: 0.75
};

K.shelfbankComponentConstants = {
  minimumShelvesDistance: 8,
  shelfThickness: 1.5
};

export default K;
