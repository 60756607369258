import React from 'react';
import { resourceActions, connect } from 'redux/index';

import ReactTooltip from 'react-tooltip';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import HudElement from './hud-element';
import Room from 'project-helpers/room';
import Floor from 'project-helpers/floor';
import Project from 'project-helpers/project';

// import Project from 'project-helpers/project';
import Elevation from 'project-helpers/elevation';

import textIcon from '../../../assets/controls-hud-element/text-icon-black.png';
import arrowIcon from '../../../assets/controls-hud-element/arrow-icon-black.png';
import textPointerIcon from '../../../assets/controls-hud-element/text-pointer-black.png';
import lineIcon from '../../../assets/controls-hud-element/line-icon-black.png';
import autogenerateElevationIcon from '../../../assets/controls-hud-element/auto-elevation-icon-black.png';
import manuallyGenerateElevationIcon from '../../../assets/controls-hud-element/manual-elevation-icon-black.png';
import showElevationIcon from '../../../assets/controls-hud-element/show-elevation-icon-black.png';
import hideElevationIcon from '../../../assets/controls-hud-element/hide-elevation-icon-black.png';
import addElevationIcon from '../../../assets/controls-hud-element/add-elevation-icon-black.png';
import measuringTapeIcon from '../../../assets/controls-hud-element/measuring-tape-icon-black.png';
import editDimensionIcon from '../../../assets/controls-hud-element/edit-dimensions-icon-black.png';
import addDatumIcon from '../../../assets/controls-hud-element/add-datum-icon-black.png';
import zoomInIcon from '../../../assets/controls-hud-element/zoom-in-icon-black.png';
import zoomOutIcon from '../../../assets/controls-hud-element/zoom-out-icon-black.png';
import plusIcon from '../../../assets/create-icon.png';
import minusIcon from '../../../assets/minus-icon-black.png';
import unlockIcon from '../../../assets/controls-hud-element/unlock-icon-black.png';
import lockIcon from '../../../assets/controls-hud-element/lock-icon-black.png';
import lockCountertopsIcon from '../../../assets/controls-hud-element/lock-countertops-icon-black.png';
import manualCountertopsIcon from '../../../assets/controls-hud-element/manual-countertop-icon-black.png';
import polygonIconBlack from '../../../assets/controls-hud-element/polygon-icon-black.png';
import rectIconBlack from '../../../assets/controls-hud-element/rect-icon-black.png';
import circleIconBlack from '../../../assets/controls-hud-element/circle-icon-black.png';
import ortholockIconBlack from '../../../assets/controls-hud-element/ortholock-icon-black.png';

class CanvasControlsHudElement extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  state = {
    precisionLocked: false,
  };

  handleZoomIn = () => {
    this.props.canvasViewRef.setState({scale: this.props.canvasViewRef.state.scale * 2});
  };

  handleZoomOut = () => {
    this.props.canvasViewRef.setState({scale: this.props.canvasViewRef.state.scale / 2});
  };

  toggleShowingElevations = () => {
    if (this.props.toggleShowingElevations) this.props.toggleShowingElevations();
  };

  toggleDimensionEditingMode = async (newMode) => {
    var {projectData} = this.props;

    var {dimensionsData, id} = this.props.project;

    var updatedDimensionsData = {
      ...dimensionsData,
      [projectData.activeDimensionsLayer]: {
        ...dimensionsData[projectData.activeDimensionsLayer],
        additiveDimensionsEditing: (newMode === 'additive')
      }
    };

    this.props.updateProject({id, props: {dimensionsData: updatedDimensionsData}, hitApi: false});
    await lib.api.update('projectVersion', {where: {id: this.props.projectData.versionId}, props: {dimensionsData: updatedDimensionsData}});
  };

  handleMeasuringTape = () => {
    if (this.props.toggleMeasuringTapeVisibility) this.props.toggleMeasuringTapeVisibility();
  };

  toggleAutogenerateElevations = async () => {
    var {projectData, project} = this.props;

    var haveConfirmed;

    if (!project.continuousElevationAutogenerationDisabled) {
      haveConfirmed = confirm('Are you sure? Manually editing elevations requires you to update elevations to be correct as you make changes to your project. Typically we do not recommend returning to autogenerated elevations after this point because it can cause loss of work.');
    }
    else if (project.continuousElevationAutogenerationDisabled) {
      haveConfirmed = confirm('Are you sure? Typically we do not recommend returning to autogenerated elevations from manually editing them because it can cause loss of work.');
    }

    if (haveConfirmed) {
      var continuousElevationAutogenerationDisabled = project.continuousElevationAutogenerationDisabled ? 0 : 1;

      await lib.api.update('projectVersion', {where: {id: projectData.versionId}, props: {continuousElevationAutogenerationDisabled}});
      this.props.updateProject({id: projectData.id, props: {continuousElevationAutogenerationDisabled}, hitApi: false});

      if (continuousElevationAutogenerationDisabled === 0) {
        //HINT automatically set drawingsData.continuousDrawingsAutogenerationDisabled = 0
        if (project.drawingsData?.continuousDrawingsAutogenerationDisabled) {
          await lib.api.update('projectVersion', {where: {id: projectData.versionId}, props: {drawingsData: {...project.drawingsData, continuousDrawingsAutogenerationDisabled: 0}}});
        }
        Project.autogenerateElevations({project: {...project, continuousElevationAutogenerationDisabled}, resourceActions: this.props});
      }
    }
  };

  handleAddDatum = () => {
    this.props.toggleDatumPopup();
  };

  addProjectGraphic = (type) => {
    var {viewMode, projectData, activeViewEntityId, activeViewEntity, activeViewEntityResourceKey, activeEntities, room, getIncludesActiveEntity} = this.props;

    var isElevationGraphic = getIncludesActiveEntity({resourceKey: 'elevation'}) || (activeViewEntityResourceKey === 'elevation');
    var roomId = isElevationGraphic ? (viewMode === 'front' ? activeViewEntity.roomId : activeViewEntityId) : room.id;
    var elevationId = isElevationGraphic ? (viewMode === 'front') ? activeViewEntityId : _.size(activeEntities) === 1 : null;

    var width, height;

    if (isElevationGraphic) {
      var elevation = viewMode === 'front' ? activeViewEntity : _.first(activeEntities);

      width = Elevation.getWidth({elevation});
      height = Elevation.getHeight({elevation});
    }
    else {
      width = 100;
      height = 100;
    }

    var position = {x: width / 2 - width / 4, y: (isElevationGraphic ? -1 : 1) * (height / 2 - height / 4)};
    var positionTo = {x: width / 2 + width / 4, y: (isElevationGraphic ? -1 : 1) * (height / 2 + height / 4)};

    var data;
    if (type === 'text') {
      data = { text: 'placeholder', position, size: {width: 50, height: 10}};
    }
    else if (type === 'rectangle') {
      data = {
        position,
        size: {width: 50, height: 50}, opacity: 1, strokeColor: 'black',
        strokeWidth: 1, strokeOpacity: 1, fillOpacity: 1, fillType: 'color', fill: 'transparent',
        fillTexture: {
          url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
          mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
        }
      };
    }
    else if (type === 'circle') {
      data = {
        position: {...position, x: position.x + 25, y: position.y + 25},
        size: {width: 50, height: 50, radius: 25},
        opacity: 1, strokeColor: 'black',
        strokeWidth: 1, strokeOpacity: 1, fillOpacity: 1, fillType: 'color', fill: 'transparent',
        fillTexture: {
          url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
          mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
        }

      }
    }
    else if (type === 'polygon') {
      data = {
        closed: false,
        position: undefined,
        dragPosition: undefined,
        points: [],
        size: {width: 50, height: 50},
        opacity: 1,
        strokeColor: '#000000',
        strokeWidth: 1,
        strokeOpacity: 1,
        fillOpacity: 1,
        fillType: 'color',
        fill: 'transparent',
        fillTexture: {
          url: 'https://henrybuilt-uploaded-files.s3.us-west-2.amazonaws.com/public/textures/brick.jpg',
          mediumId: 'brick', size: {width: 100, height: 60}, delta: {x: 0, y: 0}, rotation: 0, opacity: 1
        }
      };
    }
    else if (type === 'textPointer') {
      if (viewMode === 'front') positionTo.x = width / 2;

      data = {text: 'placeholder', position, size: {width: 25, height: 10}, to: positionTo, showArrow: true}
    }
    else {
      data = {from: position, to: positionTo};
    }

    var layer = projectData.activeProjectGraphicsLayer;

    this.props.createProjectGraphic({props: {projectId: projectData.id, versionId: projectData.versionId, type: type, roomId, elevationId, deleted: 0, isBindingDimension: 1, data, layer}});
  };

  handlePrecision = (type) => {
    let {precision} = this.props;

    if (!this.state.precisionLocked) {
      var precisionOptions = [1/16, 1/8, 1/4, 1/2, 3/4, 1, 2, 4];

      if (type === 'increase') {
        var currentPrecisionIndex = _.findIndex(precisionOptions, option => option === precision);

        if ((currentPrecisionIndex || currentPrecisionIndex === 0) && precisionOptions[currentPrecisionIndex] !== 4) precision = precisionOptions[currentPrecisionIndex + 1];

        if (precision <= K.maxPrecision) {
          if (this.props.onPrecisionChange) this.props.onPrecisionChange(precision);
        }
      } else {
        var currentPrecisionIndex = _.findIndex(precisionOptions, option => option === precision);

        if ((currentPrecisionIndex || currentPrecisionIndex === 0) && precisionOptions[currentPrecisionIndex] !== 1/16) precision = precisionOptions[currentPrecisionIndex - 1];

        if (precision >= K.minPrecision) {
          if (this.props.onPrecisionChange) this.props.onPrecisionChange(precision);
        }

        if (precisionOptions[currentPrecisionIndex] === 1/16 && this.props.project.infinitePrecision) {
          if (this.props.onPrecisionChange) this.props.onPrecisionChange(Number.EPSILON);
        }
      }
    }
  };

  get roomIsClear() {
    var {viewMode, activeViewEntity, getIncludesActiveEntity} = this.props;

    return (viewMode !== 'threeD' && (viewMode !== 'top' || (getIncludesActiveEntity({resourceKey: 'room'}) || Floor.get('rooms', {floor: activeViewEntity}).length === 1)));
  }

  get showingAnnotationsControls() {
    var {viewMode, activeViewEntity, getIncludesActiveEntity} = this.props;

    return ((viewMode === 'front') || (viewMode === 'both') || (viewMode === 'top' && this.roomIsClear));
  }

  get showingDatumControlGroup() {
    var {viewMode, room} = this.props;

    return (_.includes(['top', 'both'], viewMode) && room);
  }

  get showingCountertopSelectMode() {
    return _.includes(['top', 'both'], this.props.viewMode);
  }

  isDisabled = (viewMode) => {
    const {project} = this.props;

    if (viewMode === 'top') return false;
    else if (viewMode === 'both' || viewMode === 'room' || viewMode === 'threeD' || viewMode === 'lite') return !Project.get('rooms', {project})?.length > 0;
    else if (viewMode === 'front') return !Project.get('elevations', {project})?.length > 0;
  };

  render () {
    var {editingDimensions, bothIsShowingElevations, projectData, project, isOrthographicLockEnabled, canvasData} = this.props;
    var {showingAnnotationsControls, showingDatumControlGroup, showingCountertopSelectMode} = this;
    var {countertopsAreSelectable} = projectData;

    var iconControlGroups = [
      {
        title: 'VIEW'
      },
      ...(this.props.viewMode === 'both' ? [{
        title: 'MODE', icons: [
          {title: 'show elevations', icon: bothIsShowingElevations ? showElevationIcon : hideElevationIcon, onClick: this.toggleShowingElevations, tooltip: bothIsShowingElevations ? 'Hide Elevations' : 'Show Elevations'}
        ]
      }] : []),
      {
        title: 'ZOOM',
        icons: [
          {title: 'zoom out', icon: zoomOutIcon, onClick: this.handleZoomOut, tooltip: 'Zoom Out'},
          {title: 'zoom in', icon: zoomInIcon, onClick: this.handleZoomIn, tooltip: 'Zoom In'}
        ]
      },
      {
        title: 'DIMS',
        icons: [
          {title: 'edit dimensions', icon: editDimensionIcon, onClick: this.props.toggleDimensionEditing, tooltip: 'Edit Dimensions', isActive: editingDimensions},
          {title: 'measuring tape', icon: measuringTapeIcon, onClick: this.handleMeasuringTape, tooltip: 'Measuring Tape'},
          {title: 'ortholock', icon: ortholockIconBlack, onClick: this.props.toggleIsOrthographicLockEnabled, tooltip: isOrthographicLockEnabled ? 'Ortholock On' : 'Ortholock Off', isActive: isOrthographicLockEnabled},
        ]
      },
      ...((!_.includes(['front', 'lite', 'threeD'], this.props.viewMode)) ? [{
        title: 'ELEVATIONS',
        icons: [
          {title: 'autogenerate elevations', icon: this.props.project.continuousElevationAutogenerationDisabled === 0 ? autogenerateElevationIcon : manuallyGenerateElevationIcon, onClick: this.toggleAutogenerateElevations, tooltip: this.props.project.continuousElevationAutogenerationDisabled === 0 ? 'Automatically Generating Elevations' : 'Manually Generating Elevations'},
          {title: 'add elevations', icon: addElevationIcon, onClick: this.props.onAddElevation, tooltip: 'Add Elevation'},
        ]
      }] : []),
      {
        title: 'PRECISION',
        icons: [
          {title: 'decrease precision', icon: minusIcon, size: {width: 12, height: 12}, onClick: () => this.handlePrecision('decrease'), tooltip: 'Decrease Precision'},
          {title: this.props.precision === Number.EPSILON ? '∞' : lib.number.toFraction(this.props.precision), icon: null},
          {title: 'increase precision', icon: plusIcon, size: {width: 12, height: 12}, onClick: () => this.handlePrecision('increase'), tooltip: 'Increase Precision'},
          // {title: 'lock precision', icon: this.state.precisionLocked ? lockIcon : unlockIcon, onClick: () => this.setState({precisionLocked: !this.state.precisionLocked}), tooltip: this.state.precisionLocked ? 'Unlock Precision' : 'Lock Precision'},
        ]
      },
    ];

    if (showingAnnotationsControls) {
      iconControlGroups.push({
        title: 'ANNOTATIONS',
        icons: [
          {title: 'add line annotation', icon: lineIcon, onClick: () => this.addProjectGraphic('line'), tooltip: 'Add Line Annotation'},
          {title: 'add arrow annotation', icon: arrowIcon, onClick: () => this.addProjectGraphic('arrow'), tooltip: 'Add Arrow Annotation'},
          {title: 'add text annotation', icon: textIcon, onClick: () => this.addProjectGraphic('text'), tooltip: 'Add Text Annotation'},
          {title: 'add callout', icon: textPointerIcon, onClick: () => this.addProjectGraphic('textPointer'), tooltip: 'Add Callout'},
          {title: 'add rectangle annotation', icon: rectIconBlack, onClick: () => this.addProjectGraphic('rectangle'), tooltip: 'Add Rectangle Annotation'},
          {title: 'add circle annotation', icon: circleIconBlack, onClick: () => this.addProjectGraphic('circle'), tooltip: 'Add Circle Annotation'},
          {title: 'add polygon annotation', icon: polygonIconBlack, onClick: () => this.addProjectGraphic('polygon'), tooltip: 'Add Polygon Annotation'},
        ]
      });
    }

    if (showingDatumControlGroup) {
      iconControlGroups.push({
        title: 'DATUMS',
        icons: [
          {title: 'add datum', icon: addDatumIcon, onClick: this.handleAddDatum, tooltip: 'Add Datum'},
        ]
      });
    }

    if (editingDimensions) {
      iconControlGroups[_.findIndex(iconControlGroups, iconControlGroup => iconControlGroup.title === 'DIMS')].icons.push(
        {title: 'add dimension editing mode', icon: plusIcon, onClick: () => this.toggleDimensionEditingMode('additive'), tooltip: 'Additive Dimension Editing', style: project.dimensionsData[projectData.activeDimensionsLayer].additiveDimensionsEditing ? {padding: 2, backgroundColor: '#aaa', borderRadius: 50} : {padding: 2}},
        {title: 'sub dimension editing mode', icon: minusIcon, onClick: () => this.toggleDimensionEditingMode('subtractive'), tooltip: 'Subtractive Dimension Editing', style: !project.dimensionsData[projectData.activeDimensionsLayer].additiveDimensionsEditing ? {padding: 2, backgroundColor: '#aaa', borderRadius: 50} : {padding: 2}}
      );
    }

    if (showingCountertopSelectMode) {
      iconControlGroups.push({
        title: 'C-TOP',
        icons: [
          {title: 'countertop selectability', icon: countertopsAreSelectable ? manualCountertopsIcon : lockCountertopsIcon, onClick: this.props.toggleCountertopSelectability, tooltip: countertopsAreSelectable ? 'Countertops are Selectable' : 'Currently Countertops Locked'},
        ]
      });
    }

    var viewModeOptions = [{value: 'top', title: 'Plan'}, {value: 'front', title: 'Elevation'}, {value: 'both', title: 'Room'}, {value: 'threeD', title: '3D'}, {value: 'lite', title: 'Lite'}];

    return (
      <HudElement x='center' y='top' style={{position: 'fixed', backgroundColor: 'transparent', top: 15, padding: 0, zIndex: 1000, paddingBottom: 0, borderRadius: K.borderRadius, width: 'calc(100% - 500px)', filter: 'invert(100%)'}}>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
          {_.map(iconControlGroups, (group, index) => (
            <div key={group.title} style={{display: 'flex', flexDirection: 'column', marginBottom: K.spacing + K.margin, position: 'relative'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: K.defaultIconSize.height, paddingLeft: 7, paddingRight: 7, ...(index > 0 ? {borderLeft: '1px solid rgba(0, 0, 0, 0.2)'} : {})}}>
                {group.title === 'VIEW' ? (
                  _.map(viewModeOptions, ({value: viewMode, title}) => {
                    const isDisabled = this.isDisabled(viewMode);
                    return (<div
                      key={viewMode}
                      style={{...K.fonts.label, cursor: 'pointer', marginRight: K.margin, fontWeight: this.props.viewMode === viewMode ? 'bold' : 'normal', opacity: this.props.viewMode === viewMode ? 1 : isDisabled ? 0.3 : 0.5}}
                      onClick={!isDisabled ? () => this.props.setViewMode(viewMode) : null}
                    >{title}</div>);
                  })
                ) : (
                  _.map(group.icons, icon => (
                    <div key={icon.title} onClick={icon.onClick} style={{paddingLeft: 5, paddingRight: 5}}>
                      {group.title === 'PRECISION' && icon.icon === null ? (
                        <span>{icon.title}</span>)
                        :
                        (<>
                          <ReactTooltip id={`${icon.title}-tooltip`} effect='solid'>{icon.tooltip}</ReactTooltip>
                          <img src={icon.icon} key={`${icon.title}-icon`} alt={`${icon.title}-icon`} data-tip='tooltip' data-for={`${icon.title}-tooltip`} style={{...K.defaultIconSize, ...icon.size, cursor: 'pointer', ...icon.style, ...(_.has(icon, 'isActive') && {opacity: icon.isActive ? 1 : 0.5})}}/>
                        </>)
                      }
                    </div>
                  ))
                )}
              </div>
              {/* <div style={{...K.fonts.label, textAlign: 'center', position: 'absolute', top: 'calc(100% + 10px)', width: '100%'}}>{group.title}</div> */}
            </div>
          ))}
        </div>

        {/* <div
          style={{fontSize: '0.9em', letterSpacing: '1px', opacity: 0.5, marginLeft: 20}}
          onClick={() => Project.autogenerateElevations({project: this.props.project, ..._.pick(this.props, ['createElevations', 'updateElevations', 'destroyElevations'])})}
        >autogen elevations</div> */}
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    const {activeEntities} = ownProps;

    return {
      project: _.values(state.resources.projects.byId)[0],
      activeEntities
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projectGraphics, ['createProjectGraphic']),
    ..._.pick(resourceActions.elevations, ['createElevations', 'modifyElevations']),
    ...resourceActions.products,
    ..._.pick(resourceActions.projects, ['updateProject'])
  }
})(CanvasControlsHudElement);
