import React, {useState, useRef, useEffect, forwardRef, useCallback} from 'react';
import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Drawings from 'project-helpers/drawings';
import Room from 'project-helpers/room';

import CanvasView from 'canvas/canvas-view';
import CanvasElevation from 'project-components/canvas-elevation';
import CanvasRoom from 'project-components/canvas-room';
import { useDrag, DragPreviewImage } from 'react-dnd';
import useDetectPrint from 'hooks/use-detect-print';
import DrawingsGraphicActions from './drawings-graphic-actions';
import useSvgRenderer from 'hooks/use-svg-canvas';
import Loader from '../drawings-components/drawings-loader';

import ErrorFallback from 'components/error-fallback';
import {withErrorBoundary} from 'react-error-boundary';

import {Context} from '@symbolic/svgcanvas';

global.svgClipPathMap = {};
global.isRenderingSvg = false;

const StandardPageGraphic = withErrorBoundary(({
  //HINT drawings state
  containerDimensions, project, isEditing = false, mode, visibilityLayers, activeProjectGraphicsLayer, activeDimensionsLayer, detailLevel, fillMode, isDragging,
  //HINT graphic data
  id, size, title, isContext, isEmpty, position, scale = 1, type, model, indicators, onReplaceClick, onDelete, imageMode, moveGraphic, floorRooms, floorViewOffset, upperHeightPadding=0, lowerHeightPadding=0,
}) => {
  const layerRef = useRef();
  const svgDivRef = useRef();
  const newContextRef = useRef();

  const isPrinting = useDetectPrint();

  const [forceUpdateKey, setForceUpdateKey] = useState(lib.string.uuid());
  const [isLoading, setIsLoading] = useState(true);
  const [renderCanvasViewChildren, setRenderCanvasViewChildren] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const [svgString, setSvgString] = useState(null);

  const canvasSize = isEmpty ? lib.object.multiply(containerDimensions, 1 / 6) : Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
  const positionInPage = Drawings.getPositionInPage({position, size: canvasSize, containerDimensions});

  const isExporting = false;

  const styles = {
    canvas: {
      width: `${canvasSize.width}px`,
      height: `${canvasSize.height}px`,
      backgroundColor: 'transparent',
    },
    wrapper: {
      position: 'absolute',
      top: `${positionInPage.top}px`,
      left: `${positionInPage.left}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...(isDragging ? {border: '1px solid black'} : {}),
      // opacity,
      opacity: 1,
      cursor: isEditing ? 'move' : 'inherit',
    },
    error: {
      position: 'absolute',
      top: `${positionInPage.top}px`,
      left: `${positionInPage.left}px`,
      width: `${canvasSize.width}px`,
      height: `${canvasSize.height}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      top: !visibilityLayers.dimensions ? 'calc(100% + 60px)' : '100%',
    },
  };

  const classNames = [
    'graphic-image',
    `${type}-${id}`,
    `${!isLoading ? '' : 'show-loader loader-dark'}`,
    `${isEditing ? 'draggable' : ''}`
  ].join(' ');

  const handleForceUpdate = () => setForceUpdateKey(lib.string.uuid());
  const handleLoadingStart = () => setIsLoading(true);
  const handleLoadingEnd = () => setIsLoading(false);

  const handleDeleteClick = () => onDelete?.({id});

  if (isEmpty) return (
    <div
      key={`standard-page-graphic-${id}`}
      className={'empty graphic-image'}
      style={styles.error}
    >
      {isEditing && <DrawingsGraphicActions {...{id, onReplaceClick}} onDelete={handleDeleteClick}/>}
      <div className={'graphic-label'}>Source No Longer in Project</div>
    </div>
  );

  let viewOffset = {x: 0, y: 0};

  if (type === 'room') {
    const {min, max} = Room.getContentOutline({room: model});
    const size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = lib.object.difference({x: -min.x - size.width * 0.5, y: -min.y - size.height * 0.5}, model.plan.position);
  }
  else if (type === 'elevation') {
    const {min, max} = Elevation.getContentOutline({elevation: model});
    const size = {width: max.x - min.x, height: max.y - min.y};

    viewOffset = {x: -min.x - size.width * 0.5, y: max.y - size.height * 0.5 + (upperHeightPadding - lowerHeightPadding)};
  }

  const projectData = {
    ..._.pick(project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee']),
    activeDimensionsLayer,
    isEditingDimensions: false,
    activeProjectGraphicsLayer,
    countertopsAreSelectable: false,
    pushToUndoQueue: null,
    toggleTolerancePopupShowing: false,
    showingAlignmentIndicators: false,
  };

  const shouldRenderSVG = imageMode === 'vector';//isPrinting || isExporting || !isEditing;

  var getSVG = () => {
    if (!shouldRenderSVG) return null;
    //wait for canvas to re-render
    const svgString = newContextRef.current.getSerializedSvg();

    setSvgString(svgString);

    //wait for svg to render
    setTimeout(async () => {
      //remove white rectangles that must be meant as background rectangles
      svgDivRef.current.querySelectorAll('rect').forEach(rect => {
        if (rect.getAttribute('fill') === '#FFFFFF' && rect.getAttribute('x') === '0') {
          rect.remove();
        }
      });

      //add additional styles like stroke-dasharray to paths - konva must not be adding these in a standard way
      svgDivRef.current.querySelectorAll('path').forEach(path => {
        var stroke = path.getAttribute('stroke');

        if (stroke[0] === '{') {
          _.forEach(JSON.parse(stroke), (value, key) => {
            path.setAttribute(key, value);
          });
        }
      });

      //set path d attribute on all clipPaths (for masking)
      svgDivRef.current.querySelectorAll('svg defs clipPath').forEach(clipPath => {
        var clipPathId = clipPath.getAttribute('id');
        var correspondingGroup = svgDivRef.current.querySelector(`svg g[clip-path="url(#${clipPathId})"]`);
        var svgClipPathMapKey = correspondingGroup.querySelector('path').getAttribute('fill');
        var clipPathPolygon = global.svgClipPathMap[svgClipPathMapKey];

        var d = _.join(_.map(clipPathPolygon, (point, index) => {
          return `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`;
        }), ' ');

        clipPath.querySelector('path').setAttribute('d', d);
      });

      handleLoadingEnd();
    }, 1000);
  };

  //HINT trigger rerender when quality changes between raster and vector
  useEffect(() => {
    if (isRendered) {
      setIsRendered(false);
      handleForceUpdate();
      if (!shouldRenderSVG) setRenderCanvasViewChildren(true);
      if (svgString) setSvgString(undefined);
    }
  }, [shouldRenderSVG]);

  //HINT trigger rerender of SVG when settings change
  useEffect(() => {
    if (svgString && shouldRenderSVG) {
      handleLoadingStart();
      setSvgString(undefined);
      setIsRendered(false);
      handleForceUpdate();
    }
  }, [..._.values(visibilityLayers), shouldRenderSVG, activeDimensionsLayer, activeProjectGraphicsLayer, detailLevel, fillMode, ..._.values(canvasSize)]);

  useEffect(() => {
    if (layerRef?.current && shouldRenderSVG && !isRendered && id) {
      const canvas = layerRef.current.getCanvas();
      const oldContext = canvas.getContext()._context;

      newContextRef.current = layerRef.current.canvas.context._context = new Context({...canvasSize, ctx: oldContext});

      setRenderCanvasViewChildren(true);
      setIsRendered(true);
      handleLoadingStart();
    }
    else if (!shouldRenderSVG) {
      setRenderCanvasViewChildren(true);
      handleLoadingEnd();
    }
  }, [layerRef?.current, isRendered, shouldRenderSVG, id]);

  if (visibilityLayers.isExportingDxf && isContext) return null;

  return (
    <>
      <div
        key={`standard-page-graphic-${id}`}
        style={styles.wrapper}
      >
        {isEditing && (<DrawingsGraphicActions {...{id, onReplaceClick}} onDelete={handleDeleteClick}/>)}
        {svgString
          ? (<div ref={svgDivRef} dangerouslySetInnerHTML={{__html: svgString}}/>)
          : (<CanvasView className={classNames} key={forceUpdateKey}
              style={styles.canvas} isStatic
              {...{projectData, scale, containerSize: canvasSize, layerRef}}
              onRender={() => {
                if (shouldRenderSVG && renderCanvasViewChildren && isRendered) {
                  setTimeout(() => getSVG());
                }
              }}
            >
              {renderCanvasViewChildren && ((type === 'floor' && _.size(floorRooms)) ?
                _.map(floorRooms, (room, roomId) => (
                  <CanvasRoom key={roomId} renderForDrawings isExportingSvg={shouldRenderSVG} renderForContextCanvas={isContext} {...{room, visibilityLayers: {...visibilityLayers, elevationIndicators: indicators}, activeProjectGraphicsLayer, activeDimensionsLayer, activeDetailLevel: detailLevel, activeFillMode: fillMode, viewOffset: floorViewOffset}}/>
                ))
              :  type === 'room'
                ? (<CanvasRoom key={id} renderForDrawings isExportingSvg={shouldRenderSVG} renderForContextCanvas={isContext} {...{room: model, visibilityLayers: {...visibilityLayers, elevationIndicators: indicators}, activeProjectGraphicsLayer, activeDimensionsLayer, activeDetailLevel: detailLevel, activeFillMode: fillMode, viewOffset}}/>)
                : (<CanvasElevation key={id} renderForDrawings hideTitle isExportingSvg={shouldRenderSVG} {...{elevation: model, visibilityLayers, activeProjectGraphicsLayer, activeDimensionsLayer, activeDetailLevel: detailLevel, viewOffset, activeFillMode: fillMode}}/>)
              )}
            </CanvasView>)
        }
        {!isContext && (<div className={'graphic-label'} style={styles.title}>{title}</div>)}
      </div>
    </>
  );
}, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Standard Page Graphic'})
});
export default StandardPageGraphic;