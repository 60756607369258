import _ from 'lodash';
import lib from 'lib';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import Container from 'project-helpers/container';
import K from 'k';

var DetailsHelper = {
  getOwnedCompatibleDetailsFor({room, container, product}) {
    if (room) {
      return [];
    }
    if (container) {
      return Container.getOwnedCompatibleDetails({container});
    }
    else if (product) {
      return Product.getOwnedCompatibleDetails({product});
    }
  },

  getDetailsChildrenFor({room, container, product}) {
    if (room) {
      return Room.get('containers', {room});
    }
    else if (container) {
      return _.filter(Container.get('products', {container}), product => product.position && _.includes([undefined, 'autofilledStorage'], _.get(product, 'managedData.managedKey')));
    }
    else if (product) {
      //HINT hb hood surround handles its own children materials
      //HINT vertical barblock frames also don't handle child materials
      if (product.productId === 1641 || _.includes([1142, 1450], product.productId)){
        return [];
      }
      else {
        return Product.get('childProducts', {product});

      }
    }
  },

  getCompatibleDetailsFor({room, container, product}) {
    var compatibleDetails = DetailsHelper.getOwnedCompatibleDetailsFor({room, container, product});
    var children = DetailsHelper.getDetailsChildrenFor({room, container, product});
    var childrenResourceKey = room ? 'container' : 'product'; //HINT both containers and products have children that are products

    _.forEach(children, child => {
      var childCompatibleDetails = DetailsHelper.getCompatibleDetailsFor({[childrenResourceKey]: child});

      _.forEach(childCompatibleDetails, childCompatibleDetail => {
        var isSubproductDetail = _.split(childCompatibleDetail.key, '-').length > 1;

        if (!isSubproductDetail && !_.includes(['rightScribeMaterial', 'topScribeMaterial', 'leftScribeMaterial'], childCompatibleDetail.key)) {
          var existingCompatibleDetail = _.find(compatibleDetails, {key: childCompatibleDetail.key});

          //filter options down to shared options
          //TODO allow setting for any potential option, but only apply to valid children
          if (existingCompatibleDetail) {
            existingCompatibleDetail.options = _.filter(existingCompatibleDetail.options, option => {
              return _.includes(_.map(childCompatibleDetail.options, 'id'), option.id);
            });
          }
          else {
            compatibleDetails.push(lib.clone(childCompatibleDetail));
          }
        }
      });
    });

    return compatibleDetails;
  },

  getCleanedOwnedDetailsFor({room, container, product, includeSubproductDetails=true}) {
    var instance = room || container || product;
    var details = {..._.get(instance, 'details', {})};

    DetailsHelper.getOwnedCompatibleDetailsFor({room, container, product}).forEach(({key, options}) => {
      if ((!details[key] || !_.find(options, {id: details[key].id})) && options.length > 0) {
        var isSubproductDetail = _.split(key, '-').length > 1;

        //HINT side specific scribe materials should default to overall scribe material
        if (_.includes(['leftScribeMaterial', 'topScribeMaterial', 'rightScribeMaterial'], key) && _.get(details, 'scribeMaterial.id')) {
          details[key] = {id: _.get(details, 'scribeMaterial.id')};
        }
        else if (isSubproductDetail) {
          if (includeSubproductDetails) {
            var subproductDetailKey = _.split(key, '-')[2];

            if (details[subproductDetailKey]) {
              details[key] = {id: details[subproductDetailKey].id};
            }
            else {
              details[key] = {id: options[0].id};
            }
          }
        }
        else {
          details[key] = {id: options[0].id};
        }
      }
    });

    return details;
  },

  getFilteredCleanedOwnedDetailsFor({room, container, product, includeSubproductDetails=true}) {
    var instance = room || container || product;
    var sourceDetails = _.get(instance, 'details', {});
    var details = {};

    DetailsHelper.getOwnedCompatibleDetailsFor({room, container, product}).forEach(({key, options}) => {
      if ((!sourceDetails[key] || !_.find(options, {id: sourceDetails[key].id})) && options.length > 0) {
        var isSubproductDetail = _.split(key, '-').length > 1;

        //HINT side specific scribe materials should default to overall scribe material
        if (_.includes(['leftScribeMaterial', 'topScribeMaterial', 'rightScribeMaterial'], key) && _.get(sourceDetails, 'scribeMaterial.id')) {
          details[key] = {id: _.get(sourceDetails, 'scribeMaterial.id')};
        }
        else if (isSubproductDetail) {
          if (includeSubproductDetails) {
            var subproductDetailKey = _.split(key, '-')[2];

            if (sourceDetails[subproductDetailKey]) {
              details[key] = {id: sourceDetails[subproductDetailKey].id};
            }
            else {
              details[key] = {id: options[0].id};
            }
          }
        }
        else {
          details[key] = {id: options[0].id};
        }
      }
      else {
        details[key] = sourceDetails[key];
      }
    });

    return details;
  },

  getDetailsFor({room, container, product}) {
    var ownedDetails = lib.clone(DetailsHelper.getCleanedOwnedDetailsFor({room, container, product}));
    var details = lib.clone(ownedDetails);
    var children = DetailsHelper.getDetailsChildrenFor({room, container, product});
    var childrenResourceKey = room ? 'container' : 'product'; //HINT both containers and products have children that are products

    _.forEach(children, child => {
      var childDetailValues = DetailsHelper.getDetailsFor({[childrenResourceKey]: child});

      _.forEach(childDetailValues, (childDetailValue, key) => {
        var isSubproductDetail = _.split(key, '-').length > 1;

        if (!isSubproductDetail && !_.includes(['rightScribeMaterial', 'topScribeMaterial', 'leftScribeMaterial'], key) && !(key === 'frontPanelType' && product)) {
          var existingDetailValue = details[key];

          if (existingDetailValue === undefined) {
            details[key] = childDetailValue;
          }
          else if (existingDetailValue.id !== childDetailValue.id) {
            existingDetailValue.isMixed = true;

            if (ownedDetails[key]) existingDetailValue.instance = room || container || product;
          }
        }
      });
    });

    return details;
  },

  getDimensionsEditablePropFieldSets({product, container}) {
    const resourceKey = container ? 'container' : 'product';
    const instance = container || product;
    const instanceTypeData = container ? Container.get('containerType', {container}) : Product.get('productType', {product});
    const type = resourceKey === 'container' ? instance.type : Product.getType({product});
    let constraints = {};
    const includeHeight = type !== 'countertop' && !(resourceKey === 'product' && type === 'horizontalBarblock');
    var fieldSets = [];
    var fieldSet = [];

    if (resourceKey === 'product') {
      const constrainer = new lib.DimensionConstrainer({constraints: Product.getConstraints({product, productType: instanceTypeData})});

      constraints = constrainer.computedConstraints;
    }
    else if (resourceKey === 'container') {
      constraints = instanceTypeData.constraintsFor({container});
    }

    _.forEach(constraints, (constraint, dimensionKey) => {
      var views = dimensionKey === 'height'
        ? ['front', 'left', 'right']
        : dimensionKey === 'width' ? ['top', 'front'] : ['top', 'front', 'left', 'right'];

      if (instance.type === 'countertop') views.push('back');

      if (_.get(instance, 'customData.hasNonStandardDimensions')) {
        fieldSet.push({
          key: `dimensions.${dimensionKey}`,
          title: _.upperFirst(dimensionKey),
          type: 'size',
          views,
          step: K.minPrecision,
          minValue: 0,
        });
      }
      else if ((dimensionKey !== 'height' || includeHeight) && !(instance.type === 'kick' && dimensionKey === 'height') && !(instance.type === 'endPanel' && dimensionKey === 'width') && !(instance.type === 'capPanel' && dimensionKey === 'height')) {
        if (constraint.fixed && constraint.fixed.length > 0) {
          // if (constraint.fixed.length > 1) {
          var field = {
            key: `dimensions.${dimensionKey}`,
            title: _.upperFirst(dimensionKey),
            type: 'radio',
            noThumbnail: true,
            options: _.sortBy(_.map(constraint.fixed, value => ({value, title: value})), ['value']),
            views,
            userLenses: ['sales', 'design', 'engineering']
          };

          constraint.fixed.length > 3 ? fieldSets.push([field]) : fieldSet.push(field);
          // }
        }
        else {
          fieldSet.push({
            key: `dimensions.${dimensionKey}`,
            title: _.upperFirst(dimensionKey),
            type: 'size',
            views,
            step: constraint.step,
            minValue: constraint.min || 0,
            userLenses: ['sales', 'design', 'engineering'],
            ...(constraint.max ? {maxValue: constraint.max} : {})
          });
        }
      }
    });

    fieldSets.unshift(fieldSet);

    return _.flatMap(fieldSets);
  }
};

export default DetailsHelper;
