import React from 'react';

import lib from 'lib';
import _ from 'lodash';
import Popup from 'components/popup';
import Slideshow from '../../../components/fullscreen-slideshow';

import UIDimensionConstraints from 'components/dimension-constraints';
import 'styles/show.scss';
import 'styles/media_grid.scss';

class DocumentationSubjectView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: {
        id: undefined,
        title: '',
        category: '',
        priceRangeText: '',
        categoryId: undefined,
        image: undefined,
        documents: [],
        productMedia: [],
        summaryDetails: {},
        slideshowIsVisible: false,
        activeSlideshowMediumId: null
      },
      media: [],
      slides: [],
    };
  }

  async componentDidMount() {
    const {companyKey, isEmployee, resourceKey} = this.props;

    let {product} = await lib.api.get({
      product: {
        fields: ['id', 'notes', 'constraints', 'title', 'pricingRuleId', 'categoryId', 'categorizedTitle', 'materialAssociations', 'iconFilename'],
        where: {id: this.props.product.id},
        include: {documents: {where: {type: ['file', 'fullscreenMedia']}}, media: {}, pricingRule: {fields: ['expressions']}}
      }
    });

    const productDocuments = _.filter(product.documents, [`${companyKey}Compatible`, 1]);
    const productMedia = _.filter(product.media, [`${companyKey}Compatible`, 1]);

    let media = await lib.api.get('media', {
      where: {[`${resourceKey}Id`]: product.id, public: isEmployee ? [0, 1] : 1}, limit: 50, order: ['rank']
    });

    media = _.filter(media, [`${companyKey}Compatible`, 1]);

    const slides = _.map(media, ({type, url}) => ({caption: '', type, src: url}));

    this.setState({...this.state, subject: {
      id: product.id,
      type: 'product',
      title: product.categorizedTitle,
      documents: productDocuments,
      image: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/product_icons/${product.iconFilename}?crossorigin`,
      productMedia,
      summaryDetails: {notes: product.notes, dimensions: product.constraints}
    },
    media,
    slides,
    });
  }

  handleUpdateActiveSlideshowMediumId = ({mediumId}) => {
    this.setState({activeSlideshowMediumId: mediumId});
  }

  handleMediumClick = ({mediumId}) => {
    this.setState({slideshowIsVisible: !this.state.slideshowIsVisible, activeSlideshowMediumId: mediumId});
  }

  handleCloseSlideshow = () => {
    this.setState({slideshowIsVisible: false});
  }

  render() {
    const {subject = {}, isLoading} = this.state;

    return (
      <Popup
        onClose={() => this.props.onClose && this.props.onClose()}
        style={{
          position: 'fixed',
          right: 0,
          width: 'auto',
          padding: 'none',
          height: '100%',
          borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
          overflow: this.state.slideshowIsVisible ? 'hidden' : 'overlay',
          backgroundColor: '#f5f5f5',
          zIndex: 3,
        }}
      >
        <div className="documentation-documents-show-view company">
          <div className="subject-header">
            <div className="subject-title">{subject.title}</div>
          </div>
          <div className="subject-body">
            <div className="subject-icon-container">
              <img
                className="subject-icon"
                style={{objectFit: 'contain', width: '100px', height: '100px' }}
                src={subject.image}
              />
            </div>
            {subject.type === 'product' ? (
              <div className="summary-detail-dimensions">
                <UIDimensionConstraints dimensions={subject.summaryDetails.dimensions} />
              </div>
            ) : null}
            <div className="summary-detail-price-range">{subject.priceRangeText}</div>
            <div className="summary-detail-notes">
              <ul>
                {_.map(subject.summaryDetails.notes, note => (
                  <li className="summary-detail-note" key={note}>{note}</li>
                ))}
              </ul>
            </div>
            <div className="document-sets">
              <div className='document-set'>
                {_.map(_.sortBy(subject.documents, 'title'), document => {
                  document.extension = document.data.extension;
                  document.fileLink = `https://henrybuilt.com/documentation/documents/${document.id}/${_.kebabCase(document.title)}`;

                  return (
                    <a key={document.id} className="document-link" href={document.fileLink} target="_blank" data-document-tags={document.tags} data-document-id={document.id} data-hb-compatible={document.hbCompatible} data-vp-compatible={document.vpCompatible} data-document-keywords={document.keywords} rel="noreferrer">
                      <div className='document-title'>{document.title}</div>
                      <div className="document-tag-dots">
                        {_.map(document.filtered_tags, (tag) => (
                          <div className="tag-dot" title={tag.title} style={`background-color: ${tag.color}`}>{tag.initials}</div>
                        ))}
                      </div>
                      <div className='document-file-extension'>{document.extension}</div>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className='product-media'>
              <div className="media-grid-wrapper">
                <div className="media-grid">
                  {this.state.media.map(medium => {
                    return (
                      <div className={`media-grid-cell ${medium.type}`} key={medium.id} onClick={() => this.handleMediumClick({mediumId: medium.id})}>
                        {medium.type === 'image' || medium.type === 'vimage'
                          ? (<img src={medium.url} />)
                          : medium.type === 'video'
                            ? (<video loop autoPlay={false}>
                              <source src={medium.url} type="video/mp4"></source>
                            </video>)
                            : null
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {this.state.slideshowIsVisible && (
              <Slideshow
                onClose={this.handleCloseSlideshow}
                media={this.state.media}
                activeSlideshowMediumId={this.state.activeSlideshowMediumId}
                updateActiveSlideshowMediumId={this.handleUpdateActiveSlideshowMediumId}
              />)}
          </div>
        </div>
      </Popup>
    );
  }
}

export default DocumentationSubjectView;
