import React from 'react';
import _ from 'lodash';
import K from 'k';

import Dropdown from 'components/dropdown';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';

import upArrow from 'assets/up-arrow-black.png';

class RoomSelectorElement extends React.PureComponent {
  get roomOptions() {
    var {floors, roomsByFloor} = this.props;

    var currentRoomCount = 0;

    return _.flatten(_.map(roomsByFloor, (floor) => {
      return _.map(floor.rooms, ({id, title}) => {
        var roomNumber = currentRoomCount += 1;

        return {
          value: id,
          title: `${title}${floors.length > 1 ? ` (${floor.title})` : ''}`,
          roomNumber
        };
      });
    }))
  }

  handleActiveElevationIteration = (actionType) => {
    var newActiveElevation;
    var room = _.find(this.props.rooms, {id: this.props.activeRoomId});
    let sortedElevationsArray = Room.get('sortedElevations', {room});

    const index = _.findIndex(sortedElevationsArray, {id: this.props.activeElevationId});

    if (actionType === 'prev') {
      newActiveElevation = lib.array.prev(sortedElevationsArray, index);
    }
    else {
      newActiveElevation = lib.array.next(sortedElevationsArray, index);
    }

    if (newActiveElevation) {
      this.props.setActiveElevationId({elevationId: newActiveElevation.id});
    }
  };

  render() {
    var {rooms, elevations, activeRoomId, setActiveRoomId, setActiveElevationId, activeElevationId} = this.props;

    var activeRoom = _.find(rooms, {id: activeRoomId});
    var sortedElevations = Room.get('sortedElevations', {room: activeRoom});

    var isFirstRoom = _.findIndex(this.roomOptions, {value: activeRoomId}) === 0;
    var isLastRoom = _.findIndex(this.roomOptions, {value: activeRoomId}) + 1 === _.size(this.roomOptions);

    var nextRoomId = _.get(this.roomOptions, `[${_.findIndex(this.roomOptions, {value: activeRoomId}) + 1}].value`);
    var prevRoomId = _.get(this.roomOptions, `[${_.findIndex(this.roomOptions, {value: activeRoomId}) - 1}].value`);

    var arrowStyles = {
      container: {width: '1.5rem', height: '1.5rem', position: 'relative'},
      icon: {width: '100%', height: '100%', cursor: 'pointer', position: 'absolute', opacity: 0.7}
    };

    return (
      <div>
        {activeElevationId && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginRight: '1.5rem'}}>
              {_.findIndex(sortedElevations, {id: activeElevationId}) !== 0 && (
                  <div
                    style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '1px', cursor: 'pointer', opacity: 0.5}}
                    onClick={() => this.handleActiveElevationIteration('prev')}
                  >
                    <img src={upArrow} style={{transform: 'rotate(-90deg)', ...arrowStyles.icon, width: '17px', height: '17px'}} />
                  </div>
              )}
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Dropdown
                options={_.map(sortedElevations, (elevation) => ({
                  value: elevation.id,
                  title: Elevation.getTitle({elevation})
                }))}
                value={activeElevationId}
                onChange={(value) => setActiveElevationId({elevationId: value})}
                style={{}}
                labelStyle={{letterSpacing: '0.15em', fontSize: '1.25em', fontWeight: 400, textTransform: 'uppercase', opacity: 0.5}}
                contentContainerStyle={{borderRadius: '5px', padding: '10px', display: 'flex', flexDirection: 'column', marginLeft: '-14px', marginRight: 'auto'}}
                showCaret={false}
                hasFixedHeight={true}
              />
            </div>
            <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginLeft: '1.5rem'}}>
              {_.findIndex(sortedElevations, {id: activeElevationId}) !== sortedElevations.length - 1 && (
                <div
                  style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', cursor: 'pointer', opacity: 0.5}}
                  onClick={() => this.handleActiveElevationIteration('next')}
                >
                  <img src={upArrow} style={{transform: 'rotate(90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
                </div>
              )}
            </div>
          </div>
        )}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginRight: '1.5rem'}}>
              {(this.roomOptions.length > 1 && !isFirstRoom) && (
                <div
                  style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '1px', cursor: 'pointer', opacity: 0.5}}
                  onClick={() => setActiveRoomId({roomId: prevRoomId})}
                >
                  <img src={upArrow} style={{transform: 'rotate(-90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
                </div>
              )}
            </div>
            <div>
              <Dropdown
                options={this.roomOptions}
                value={activeRoomId}
                onChange={(value) => setActiveRoomId({roomId: value})}
                style={{}}
                labelStyle={{fontSize: '1.5em', letterSpacing: '0.06em'}}
                contentContainerStyle={{borderRadius: '5px', padding: '10px', display: 'flex', flexDirection: 'column', marginLeft: '-14px', marginRight: 'auto'}}
                showCaret={false}
                hasFixedHeight={true}
              />
            </div>
            <div style={{...K.defaultIconSize, width: '17px', height: '17px', marginLeft: '1.5rem'}}>
              {(this.roomOptions.length > 1 && !isLastRoom) && (
                <div
                  style={{justifyContent: 'center', ...K.defaultIconSize, width: '17px', height: '17px', paddingTop: '1px', cursor: 'pointer', opacity: 0.5}}
                  onClick={() => setActiveRoomId({roomId: nextRoomId})}
                >
                  <img src={upArrow} style={{transform: 'rotate(90deg)', ...arrowStyles.icon, width: '17px', height: '17px', }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoomSelectorElement;
