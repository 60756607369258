import React, { useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Room from 'project-helpers/room';
import Scope from 'project-helpers/scope';
import Project from 'project-helpers/project';
import Container from 'project-helpers/container';
import Product from 'project-helpers/product';
import Volume from 'project-helpers/volume';
import Elevation from 'project-helpers/elevation';
import PositionHelper from 'helpers/position-helper';
import CanvasContainer from './canvas-container';
import CanvasArchElement from './canvas-arch-element';
import CanvasDatum from './canvas-datum';
import CanvasDimensionLine from 'canvas/dimensions/canvas-dimension-line';
import CanvasProjectGraphic from './canvas-project-graphic';
import CanvasVolume from './canvas-volume';
import CanvasScope from 'project-components/canvas-scope';
import getRoomDimensionSets from 'dimensions/room/getRoomDimensionSets';

import { withErrorBoundary } from 'react-error-boundary';

import { EditableCanvasPolyline, EditableCanvasLine, CanvasDataContext, CanvasSelectionContext, CanvasElevationIndicator, CanvasLine, CanvasText, CanvasRect, CanvasErrorFallback } from 'canvas';
import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';
import ArchElement from 'project-helpers/arch-element';
import ProjectDataContext from 'contexts/project-data-context';

class CanvasScopeGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.shouldUpdateDimensionSets = props.visibilityLayers.dimensions && props.room.plan.closed && !props.renderForContextCanvas;
    this.shouldUpdateContainerGridPositions = true;
  }

  shouldComponentUpdate(nextProps) {
    var {renderForContextCanvas, room, canvasData, visibilityLayers, containers, projectData, renderForDrawings} = this.props;
    var showDimensions = visibilityLayers.dimensions;

    var selectedEntitiesChanged = nextProps.selectionData?.activeEntities && this.props.selectionData?.activeEntities && (_.size(nextProps.selectionData.activeEntities) !== _.size(this.props.selectionData.activeEntities) || this.difference(nextProps.selectionData.activeEntities, this.props.selectionData.activeEntities).length > 0);

    this.shouldUpdateDimensionSets = (projectData && !selectedEntitiesChanged && !renderForContextCanvas && !this.state.preventDimsCalculation && showDimensions && room.plan.closed) && (!this.dimensionSets || canvasData.scale === nextProps.canvasData?.scale || renderForDrawings);

    this.shouldUpdateContainerGridPositions = !_.some(containers, {eventType: 'transform'});

    if (!this.props.room || !this.props.room.id) return true;

    if (this.props.renderForDrawings || nextProps.renderForDrawings) return true;

    var preventUpdate = false;

    const hasRoomChanged = this.difference(nextProps.room, this.props.room).length > 0;
    var haveDimensionsUpdated = nextProps.projectData.activeDimensionsLayer !== this.props.projectData.activeDimensionsLayer || _.some(nextProps.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer], (dimensionData, dimensionDataKey) => {
      var currentDimensionData = this.props.projectData.dimensionsData[this.props.projectData.activeDimensionsLayer][dimensionDataKey];
      var shouldCauseRerender = false;

      if (dimensionDataKey === 'additiveDimensionsEditing') {
        shouldCauseRerender = currentDimensionData !== dimensionData;
      }
      else {
        var dataDifferent = false;

        if (_.isArray(dimensionData) || _.isArray(currentDimensionData)) {
          dataDifferent = _.flatMap(dimensionData, (data, index) => this.difference(data, _.get(currentDimensionData, `[${index}]`))).length > 0;
        }
        else {
          dataDifferent = this.difference(dimensionData, currentDimensionData).length > 0;
        }

        shouldCauseRerender = _.size(dimensionData) !== _.size(currentDimensionData) || dataDifferent;
      }

      return shouldCauseRerender;
    });

    if (hasRoomChanged || haveDimensionsUpdated || this.props.room.eventType === 'transform') return true;

    var hasReduxStateUpdated = false;
    var manuallyHandledPropKeys = ['room', 'volumes', 'containers', 'archElements', 'sortedElevations', 'projectGraphics', 'scopes', 'products'];
    var manuallyHandledObjectPropKeys = ['volumes', 'containers', 'archElements', 'sortedElevations', 'projectGraphics', 'scopes', 'products'];

    _.forEach(manuallyHandledObjectPropKeys, key => {
      if (!hasReduxStateUpdated) {
        var sizeDifferent = _.size(nextProps[key]) !== _.size(this.props[key]);
        var resourcesDifferent = _.flatMap(nextProps[key], (resource, index) => this.difference(resource, _.get(this.props, `${key}[${index}]`))).length > 0;

        if (sizeDifferent || resourcesDifferent) {
          hasReduxStateUpdated = true;
        }
      }
    });

    const haveNonReduxPropsUpdated = !_.isEqual(_.omit(nextProps, manuallyHandledPropKeys), _.omit(this.props, manuallyHandledPropKeys));

    return (haveNonReduxPropsUpdated || hasReduxStateUpdated) && !preventUpdate;
  }

  difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  handleScopeTitleClick = () => {
    var {selectionData, room} = this.props;
    var {activeEntities, setActiveEntities} = selectionData;
    var {id} = room;

    if (_.get(activeEntities, '0.id') !== id) {
      setActiveEntities({entities: [{resourceKey: 'room', id}]});
    }
  };

  render() {
    var {scope, room, position, viewOffset, visibilityLayers, selectionData, renderForContextCanvas, canvasData, containers, activeProjectGraphicsLayer, activeDetailLevel, activeFillMode, projectData, renderForDrawings, activeUserLense, isExportingSvg} = this.props;
    var {activeEntities} = selectionData;
    var origin = lib.object.sum(position, viewOffset);

    var {
      reveals: showRevealSymbols,
      unitNumbers: showUnitNumbers,
      grainFlow: showGrainFlow,
      projections: showProjections,
      projectGraphics: showProjectGraphics,
      wallsAndArchElements: showWallsAndArchElements,
      dimensions: showDimensions,
      bindingDimensions: showBindingDimensions,
      datums: showDatums,
      perspective: showPerspective,
      canvasSettings: showCanvasSettings,
      canvasSettingsProductDetails: showProductDetails
    } = visibilityLayers;

    if (this.shouldUpdateDimensionSets) {
      // this.dimensionSets = getRoomDimensionSets({room, projectData, canvasData, showWallsAndArchElements});
    }

    if (this.shouldUpdateContainerGridPositions) {
      this.gridPositionByContainerId = {};

      _.forEach(_.values(containers), (container, i) => {
        var {x, y} = Container.getScopeGridPosition({container, i, scope, containersLength: _.size(containers)});

        this.gridPositionByContainerId[container.id] = {x, y};
      });
    }

    return (<>
      {scope.title ? <CanvasText
        text={scope.title || 'Untitled Room'}
        fontSize={canvasData.scale * 8}
        verticalAlign='top'
        fontStyle='bold'
        align='center'
        listening={true}
        onClick={this.handleScopeTitleClick}
        {...lib.object.sum(PositionHelper.toCanvas(origin, canvasData))}
      /> : null}
      {_.map(_.values(containers), (container, i) => {
        var {x, y} = this.gridPositionByContainerId[container.id];

        return (
          <React.Fragment key={container.id}>
            {false && (
              <>
                <CanvasText {...PositionHelper.toCanvas(lib.object.sum(origin, {x: x + 5, y: y + 3}, {x: -(200 / 2) - (container.dimensions.width / 2), y: -(200 / 2) + (container.dimensions.height / 2)}), canvasData)} text={i} fontSize={canvasData.scale * 8}
                  verticalAlign='top'
                  fontStyle='bold'
                  align='center'
                  listening={false}
                />
                <CanvasRect position={PositionHelper.toCanvas(lib.object.sum(origin, {x, y}, {x: -(200 / 2) - (container.dimensions.width / 2), y: -(200 / 2) + (container.dimensions.height / 2)}), canvasData)} width={200 * canvasData.scale} height={200 * canvasData.scale} stroke={'red'}/>
              </>
            )}
            <CanvasContainer
              key={container.id} id={container.id}
              viewKey={'front'}
              viewMode={'lite'}
              nonSpacialSideKey={'front'}
              overridePosition={{x: 0, y: 0}}
              viewOffset={lib.object.sum(origin, {x: x, y: y})}
              isNonSpacial
              {...{id: container.id, showRevealSymbols, showUnitNumbers, showGrainFlow, showProjections, showPerspective, room,
                activeDetailLevel, activeFillMode, activeUserLense, showWallsAndArchElements, showCanvasSettings, showProductDetails, renderForDrawings,
                visibilityLayers
              }}
            />
          </React.Fragment>);
      })
      }
    </>);
  }
}

function CanvasScopeGridWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var selectionData = useContext(CanvasSelectionContext);
  var projectData = useContext(ProjectDataContext);

  if (props.renderForContextCanvas) {
    projectData = _.omit(projectData, ['dimensionsData']);
    selectionData = {};
  }
  else if (props.renderForDrawings) {
    selectionData = {};
  }

  return <CanvasScopeGrid {...props} {...{canvasData, selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {scope} = ownProps;
    var props = {};

    if (scope && scope.id) {
      props = {
        ...Scope.get(['containers', 'room'], {scope, state})
      };
    }

    return props;
  },
  mapDispatch: {
    ...resourceActions.elevations,
    ..._.pick(resourceActions.scopes, ['updateScope', 'updateScopes', 'destroyScopes']),
    ..._.pick(resourceActions.rooms, ['destroyRoom', 'updateRoom']),
    ..._.pick(resourceActions.walls, ['destroyWall', 'destroyWalls', 'updateWall', 'updateWalls', 'createWall', 'createWalls', 'modifyWalls']),
    ..._.pick(resourceActions.containers, ['destroyContainers']),
    ..._.pick(resourceActions.products, ['destroyProducts', 'updateProduct', 'updateProducts']),
    ..._.pick(resourceActions.productOptions, ['destroyProductOptions']),
    ..._.pick(resourceActions.archElements, ['destroyArchElements']),
    ..._.pick(resourceActions.projectGraphics, ['destroyProjectGraphics']),
    ..._.pick(resourceActions.volumes, ['destroyVolumes']),
    ..._.pick(resourceActions.parts, ['updateParts']),
  }
})(CanvasScopeGridWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasScopeGrid'})
});
