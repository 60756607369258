import _ from 'lodash';
import K from 'k';
import Container from '.';
import Product from 'project-helpers/product';
// import lib from 'lib';

var Container3dNodesHelper = {
  get3dNodesFor: ({container, isArchetype, activeFillMode, isContextElement}) => {
    var {companyKey, containerType, dependencies} = Container.get(['companyKey', 'containerType', 'dependencies'], {container});

    let fill = Container.getFill({container, elevation: null, activeFillMode: (activeFillMode === 'grayscale') ? 'grayscale' : 'unitType'});
    let defaultFill = fill;
    var hatchFillData = {};

    if (activeFillMode === 'materialColors') {
      var materialKey = {
        endPanel: 'endPanel',
        countertop: 'countertop',
        opencase: 'front',
        capPanel: 'topCapPanel',
        islandSeating: 'islandBackPanel',
        sculptedPanel: 'panel',
        countertopSupport: 'seatingSupportRod',
        islandExtension: 'islandExtensionFrame',
        backsplash: 'panel',
        opencase: 'panel'
      }[container.type] || 'front';

      if (isArchetype) {
        var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);

        var compatibleDetails = undefined;
        var currentDetails = container.details;

        if (compatibleDetails && !_.find(compatibleDetails, {key: `${materialKey}Material`})) {
          if (_.get(currentDetails['panelMaterial'], 'id') && _.find(compatibleDetails, {key: `panelMaterial`})) {
            materialKey = 'panelMaterial';
          }
          else if (_.get(currentDetails['boxMaterial'], 'id') && _.find(compatibleDetails, {key: `boxMaterial`})) {
            materialKey = 'boxMaterial';
          }
        }

        var materialId = _.get(currentDetails[`${materialKey}Material`], 'id');

        var material = _.find(flattenedMaterials, {id: materialId});
        fill = _.get(material, 'color') || fill;

        if (container.customData.isByOthers) fill = container.customData.fillColor || fill;
      }
      else {
        var hatchFillData = Container.getHatchFillData({container, viewKey: 'front', includeAll: true, activeFillMode});

        defaultFill = hatchFillData.hatchFills[`${materialKey}Material`] || fill;
      }

      if (_.includes(['baseFreestandingAppliance', 'wallFreestandingAppliance', 'tallFreestandingAppliance'], container.type)) {
        defaultFill = K.applianceAppearancePropsByTheme().fill.light;
      }
    }

    if (isArchetype) {
      return [
        {
          nodeProps: {
            position: {x: 0, y: 0, z: 0},
            dimensions: container.dimensions,
            rotation: 0, fill: defaultFill, opacity: 1
          }
        }
      ];
    }

    var nodes = [];

    var scribesData = Container.getScribesData({container});
    var wrapSizes = Container.getWrapSizes({container});

    //Scribes
    if (Container.getSupportsScribe({container})) {
      _.forEach(scribesData, (scribe) => {
        var scribeDepth = 0.75;

        //show breakpoints on tall or wide scribes
        //show corner scribe backer

        var scribeY = scribe.sideKey === 'top' ? container.dimensions.height : 0

        if (scribe.sideKey !== 'top') {
          var bottomThickness = _.get(container, 'customData.wrap.bottomThickness') || 0;

          if (!(!container.customData.wrap || !container.customData.wrap.bottom)) {
            scribeY = bottomThickness;
          }

          if (_.get(scribe, 'model.kickExtending', false)) {
            scribeY = Container.getKickHeight({container});
          }
        }

        nodes.push({
          nodeProps: {
            position: {
              x: scribe.sideKey === 'top'
                ? scribe.xOrigin
                : (scribe.sideKey ===  'left' ? - scribe.distance : container.dimensions.width),
              y: scribeY,
              z: container.dimensions.depth - scribeDepth - (scribe.inset || 0)
            },
            dimensions: {
              width: scribe.sideKey === 'top'
                ? _.sum(_.map(scribe.productInstancesData, 'dimensions.width'))
                : scribe.distance,
              height: scribe.sideKey === 'top'
                ? scribe.distance
                : scribe.length,
              depth: scribeDepth
            },
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `${scribe.sideKey}ScribeMaterial`, defaultFill), opacity: 1
          }
        });
      });
    }

    //Kick
    if (Container.getHasKick({container})) {
      var kickHeight = Container.getKickHeight({container});

      if (kickHeight && kickHeight !== 0) {
        //TODO update position when flush
        //TODO handle inset kick
        //TODO
        var scribedKicks = {};
        var mainKickLeft = wrapSizes.left;
        var netUnwrappedScribeDistance = 0;
        var width = container.dimensions.width - wrapSizes.left - wrapSizes.right;
        var kickInset = Container.getKickInset({container});
        var insetKickData = Container.getInsetKickData({container});

        if (insetKickData.left) {
          var leftIndent = 3.75 - wrapSizes.left;
          mainKickLeft += leftIndent;
          netUnwrappedScribeDistance -= leftIndent;
        }
        if (insetKickData.right) {
          netUnwrappedScribeDistance -= (3.75 - wrapSizes.right);
        }

        _.forEach(scribesData, (scribeData, sideKey) => {
          if (_.includes(['left', 'right'], sideKey)) {
            var kickDistance = scribeData.distance;
            if (scribeData.adjacentType === 'container' && scribeData.model.hasKick) kickDistance += 3.75 + 1/8; //kick inset + panel overhang

            if (!_.get(container, `customData.wrap[${sideKey}]`)) {
              netUnwrappedScribeDistance += kickDistance;

              if (sideKey === 'left') {
                mainKickLeft -= kickDistance;
              }
            }
            else {
              if (scribeData.adjacentType === 'container' && scribeData.model.hasKick) {
                var scribeKickLeft = sideKey === 'left' ? -(kickDistance) : container.dimensions.width;
                nodes.push({
                  nodeProps: {
                    position: {x: scribeKickLeft, y: 0, z: container.dimensions.depth - kickInset - 0.75},
                    dimensions: {
                      width: kickDistance,
                      height: kickHeight,
                      depth: 0.75
                    },
                    rotation: 0, fill: _.get(hatchFillData.hatchFills, `kickMaterial`, defaultFill), opacity: 1
                  }
                });
              }
            }
          }
        });

        nodes.push({
          nodeProps: {
            position: {
              x: mainKickLeft,
              y: 0,
              z: container.dimensions.depth - kickInset - 0.75
            },
            dimensions: {
              width: width + netUnwrappedScribeDistance,
              height: kickHeight,
              depth: 0.75
            },
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `kickMaterial`, defaultFill), opacity: 1
          }
        });
      }
    }

    if (Container.getHasSubcounter({container})) {
      var subcounterHeight = Container.getSubcounterHeight({container});

      if (subcounterHeight && subcounterHeight !== 0) {
        //TODO getXOffset when extending across scribe
        //TODO get width when extending across scribe
        //TODO
        var left = 0;
        //TODO
        var width = container.dimensions.width;
        nodes.push({
          nodeProps: {
            position: {
              x: 0,
              y: container.dimensions.height - subcounterHeight,
              z: 0
            },
            dimensions: {
              width,
              height: subcounterHeight,
              depth: container.dimensions.depth - subcounterHeight
            },
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `subcounterMaterial`, defaultFill), opacity: 1
          }
        });
      }
    }

    if (Container.getHasWrap({container})) {
      var meldedContainersData = Container.getMeldedContainersData({container});
      var wrap = _.get(container, 'customData.wrap', {});
      var endPanelSideKeys = _.filter(['left', 'right'], sideKey => {
        return wrap[sideKey] && !(meldedContainersData[sideKey] && !meldedContainersData[sideKey].isPrimaryContainer);
      });

      var wrapWidths = Container.getWrapSizes({container});
      var wrapDepths = Container.getWrapPanelWidths({container});
      var wrapHeights = Container.getEndPanelHeights({container, scribesData, companyKey, dependencies})

      _.forEach(endPanelSideKeys, wrapSideKey => {
        const meldedContainerData = meldedContainersData[wrapSideKey];
        var x, y;
        var wrapWidth = wrapWidths[wrapSideKey];
        var customWrapPanelWidth = _.get(wrap, `depths.${wrapSideKey}`);
        var customPanelExtension = _.get(wrap, `backExtension.${wrapSideKey}`, 0);
        var customWrapWidthOffset = customWrapPanelWidth ? (customWrapPanelWidth - container.dimensions.depth - wrap.overhang + customPanelExtension) : customPanelExtension;
        var wrapDepth = meldedContainerData ? _.get(meldedContainerData, 'netMeldedDepth', 0) : wrapDepths[wrapSideKey];

        x = wrapSideKey === 'left' ? 0 : container.dimensions.width - wrapWidths[wrapSideKey];
        y = -_.get(meldedContainerData, 'otherContainersWrapPanelWidth', 0) - customWrapWidthOffset;

        nodes.push({
          nodeProps: {
            position: {x, y: 0, z: y},
            dimensions: {
              width: wrapWidth,
              height: _.sum(_.get(wrapHeights[wrapSideKey], 'visible')),
              depth: wrapDepth
            },
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `endPanelMaterial`, defaultFill), opacity: 1
          }
        });
      });

      if (wrap.top === 1) {
        var x, y, wrapHeight, wrapWidth;
        wrapHeight = wrapSizes['top'];
        x = (scribesData.left && !container.customData.wrap.left) ? -scribesData.left.distance : wrapSizes.left;
        y = 0;
        wrapWidth = _.sum(Container.getCapPanelWidths({container}));

        nodes.push({
          nodeProps: {
            position: {x, y: container.dimensions.height - wrapHeight, z: y},
            dimensions: {
              width: wrapWidth,
              height: wrapHeight,
              depth: wrapDepths['top']
            },
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `topCapPanelMaterial`, defaultFill), opacity: 1
          }
        });
      }

      //TODO
      if (wrap.bottom) {
        // var x, y, wrapHeight, wrapWidth;
        // wrapHeight = wrapSizes['top'];
        // x = (scribesData.left && !container.customData.wrap.left) ? -scribesData.left.distance : wrapSizes.left;
        // y = 0;
        // wrapWidth = _.sum(Container.getCapPanelWidths({container}));

        // nodes.push({
        //   nodeProps: {
        //     position: {x, y: container.dimensions.height - wrapHeight, z: y},
        //     dimensions: {
        //       width: wrapWidth,
        //       height: wrapHeight,
        //       depth: wrapDepths['top']
        //     },
        //     rotation: 0, fill: _.get(hatchFillData.hatchFills, `topCapPanelMaterial`, defaultFill), opacity: 1
        //   }
        // });
      }
    }

    if (container.type === 'floatingShelves') {
      var products = Container.get('products', {container});

      _.forEach(products, product => {
        var productFill = defaultFill;

        if (activeFillMode === 'materialColors') {
          var productHatchFillData = Product.getHatchFillData({product, container, viewKey: 'front', activeFillMode});

          productFill = productHatchFillData.hatchFill || productHatchFillData.hatchFills.front || productHatchFillData.hatchFills.panel;
        }

        nodes.push({
          nodeProps: {
            position: {x: product.position.x, y: -product.position.y, z: product.position.z},
            dimensions: product.dimensions,
            rotation: 0, fill: productFill, opacity: 1
          }
        });
      });
    }
    else if (container.type === 'islandExtension') {
      nodes.push({
        nodeProps: {
          position: {x: 0, y: 0, z: container.dimensions.depth - 1.5},
          dimensions: {width: 1.5, height: container.dimensions.height, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: container.dimensions.width - 1.5, y: 0, z: container.dimensions.depth - 1.5},
          dimensions: {width: 1.5, height: container.dimensions.height, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: 1.5, height: 1.5, depth: container.dimensions.depth},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: container.dimensions.width - 1.5 - 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: 1.5, height: 1.5, depth: container.dimensions.depth},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5 + 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: container.dimensions.width - 3 - 3, height: 1.5, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5 + 1.5, y: container.dimensions.height - 1.5, z: container.dimensions.depth - 1.5},
          dimensions: {width: container.dimensions.width - 3 - 3, height: 1.5, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      );
    }
    //HINT TODO show based on product
    else if (container.type === 'hbIslandExtension') {
      nodes.push({
        nodeProps: {
          position: {x: 0, y: 0, z: container.dimensions.depth - 1.5},
          dimensions: {width: 1.5, height: container.dimensions.height, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: container.dimensions.width - 1.5, y: 0, z: container.dimensions.depth - 1.5},
          dimensions: {width: 1.5, height: container.dimensions.height, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: 1.5, height: 1.5, depth: container.dimensions.depth},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: container.dimensions.width - 1.5 - 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: 1.5, height: 1.5, depth: container.dimensions.depth},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5 + 1.5, y: container.dimensions.height - 1.5, z: 0},
          dimensions: {width: container.dimensions.width - 3 - 3, height: 1.5, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      {
        nodeProps: {
          position: {x: 1.5 + 1.5, y: container.dimensions.height - 1.5, z: container.dimensions.depth - 1.5},
          dimensions: {width: container.dimensions.width - 3 - 3, height: 1.5, depth: 1.5},
          rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandExtensionFrameMaterial`, defaultFill), opacity: 1
        }
      },
      );
    }
    else if (container.type === 'islandSeating') {
      var seatingDepth = container.customData.seatingDepth || 6;

      if (!container.customData.countertopSupportDisabled) {
        var countertopSupportDepth = 2.5;
        var countertopSupportHeight = 2.5;

        nodes.push({
          nodeProps: {
            position: {x: wrapSizes.left, y: container.dimensions.height - countertopSupportHeight, z: container.dimensions.depth - 2 - countertopSupportDepth},
            dimensions: {width: container.dimensions.width - wrapSizes.left - wrapSizes.right, height: countertopSupportHeight, depth: countertopSupportDepth},
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `seatingSupportRodMaterial`, defaultFill), opacity: 1
          }
        });
      }

      if (!container.customData.panelsAreDisabled) {
        nodes.push({
          nodeProps: {
            position: {x: wrapSizes.left, y: 0, z: container.dimensions.depth - 0.75 - seatingDepth},
            dimensions: {width: container.dimensions.width - wrapSizes.left - wrapSizes.right, height: container.dimensions.height, depth: 0.75},
            rotation: 0, fill: _.get(hatchFillData.hatchFills, `islandBackPanelMaterial`, defaultFill), opacity: 1
          }
        });
      }
    }
    else {
      var frontDropzoneSize = Container.getDropzoneSize({container, viewKey: 'front'});
      var frontDropzoneInset = Container.getDropzoneInset({container, viewKey: 'front'});
      var topDropzoneSize = Container.getDropzoneSize({container, viewKey: 'top'});
      var topDropzoneInset = Container.getDropzoneInset({container, viewKey: 'top'});

      var dropzoneSize = {width: frontDropzoneSize.width, height: frontDropzoneSize.height, depth: (topDropzoneSize.depth || topDropzoneSize.height)};
      var dropzoneOrigin = {x: frontDropzoneInset.x, y: -frontDropzoneInset.y, z: topDropzoneInset.y};

      if (container.type === 'baseWithChase') dropzoneSize.depth = container.customData.unitDepth;
      if (container.type === 'wallPanel') dropzoneSize.depth -= container.customData.cleatDepth || 0;

      var products = Container.get('products', {container});

      var filteredProducts = _.filter(products, product => {
        return !Product.getIsManaged({product}) || _.get(product, 'managedData.managedKey') === 'autofilledStorage';
      })

      if (isContextElement || filteredProducts.length === 0) {
        if (container.type === 'wall') {
          nodes.push({
            nodeProps: {
              position: dropzoneOrigin,
              dimensions: {...dropzoneSize, depth: dropzoneSize.depth - 0.75},
              rotation: 0,
              fill: _.get(hatchFillData.hatchFills, `boxMaterial`, defaultFill), opacity: 1
            }
          });
          nodes.push({
            nodeProps: {
              position: {...dropzoneOrigin, z: dropzoneOrigin.z + (dropzoneSize.depth - 0.75)},
              dimensions: {...dropzoneSize, depth: 0.75},
              rotation: 0,
              fill: defaultFill, opacity: 1
            }
          });
        }
        else {
          nodes.push({
            nodeProps: {
              position: dropzoneOrigin,
              dimensions: dropzoneSize,
              rotation: 0,
              fill: defaultFill, opacity: 1
            }
          });
        }
      }
      else {
        _.forEach(filteredProducts, product => {
          var productFill = defaultFill;
          var productHatchFillData = {};
          if (activeFillMode === 'materialColors') {
            productHatchFillData = Product.getHatchFillData({product, container, viewKey: 'front', includeAll: true, activeFillMode});

            productFill = productHatchFillData.hatchFill || productHatchFillData.hatchFills.front || productHatchFillData.hatchFills.panel || productFill;
          }

          var productPosition3d = Product.getPosition3d({product});
          var position3d = lib.object.sum(dropzoneOrigin, productPosition3d);

          var productDims = _.clone(product.dimensions);

          if (_.includes([...K['vp'].ids.verticalHiddenPanels, ...K['hb'].ids.verticalHiddenPanels, ...K['vp'].ids.horizontalHiddenPanels, ...K['hb'].ids.horizontalHiddenPanels], product.productId)) {
            var swapDimKey = _.includes([...K['vp'].ids.verticalHiddenPanels, ...K['hb'].ids.verticalHiddenPanels], product.productId) ? 'width' : 'height';

            if (swapDimKey === 'height') productDims.height = product.dimensions.depth;
            if (swapDimKey === 'width') productDims.width = product.dimensions.depth;

            productDims.depth = product.dimensions[swapDimKey];
          }

          //TODO some logic
          var hasBox = productDims.depth > 5;
          var isStandaloneAppliance = _.includes([439, 438, 440, 436, 437, 747, 748, 746, 779, 777, 780, 1223, 1224, 1242, 1244], product.productId);
          var isShelfbank = _.includes([165, 167, 164, 166, 163, 162, 1379], product.productId);

          //TODO open shelfbank has some unique aspects to add
          if (isShelfbank) {
            const slider = (props) => {
              var {x, y, width, height, dualSlider, dualSliderOffset, sliderIsFullHeight} = props;

              nodes.push({
                nodeProps: {
                  position: {x: position3d.x + x, y: position3d.y + y, z: position3d.z + (product.dimensions.depth - 0.75)},
                  dimensions: {width, height, depth: 0.75},
                  rotation: 0, fill: _.get(productHatchFillData.hatchFills, `glass`) || productFill, opacity: 0.5
                }
              });
            };
            var shelfWidth = 1.5;
            var partitionWidth = 3/4;
            var {customData} = product;
            var shelfOffsets = customData.shelfOffsets && _.map(_.split(customData.shelfOffsets, ','), offset => parseFloat(offset));
            var isOpen = product.productId === 162;
            var hasPartitions = !isOpen || product.dimensions.depth === 18.5;
            var {bottomShelfOffset = 0, hasOnly1Shelf = false} = customData;

            if (isOpen && hasOnly1Shelf && product.dimensions.depth !== 18.5) {
              shelfOffsets = [];
            }

            if (!shelfOffsets) {
              //open shelfbank
              if (isOpen) {
                var shelfCount = 2;

                if (product.dimensions.height - bottomShelfOffset > 24) shelfCount = 3;
                if (product.dimensions.height - bottomShelfOffset > 40) shelfCount = 4;

                shelfOffsets = [];

                if (shelfCount === 2) {
                  shelfOffsets.push(product.dimensions.height - bottomShelfOffset - 2 * shelfWidth)
                }
                else {
                  _.times(shelfCount - 1, n => {
                    shelfOffsets.push((product.dimensions.height - bottomShelfOffset - shelfCount * shelfWidth) / (shelfCount - 1))
                  })
                }
              }
              else {
                var shelfCount = product.dimensions.height >= 19 ? 3 : 2;
                shelfOffsets = [];

                if (shelfCount === 2) {
                  shelfOffsets.push(product.dimensions.height - 2 * shelfWidth)
                }
                else {
                  shelfOffsets.push((product.dimensions.height - 3 * shelfWidth) / 2, (product.dimensions.height - 3 * shelfWidth) / 2)
                }
              }
            }

            var accumulativeShelfSpacing = 0;
            var shelfSpacingsArray = [(isOpen ? bottomShelfOffset : 0)];

            _.forEach(shelfOffsets, offset => {
              accumulativeShelfSpacing = accumulativeShelfSpacing + offset + shelfWidth;

              shelfSpacingsArray.push((isOpen ? bottomShelfOffset : 0) + accumulativeShelfSpacing);
            });

            var hasSlider = _.includes([163, 164, 165, 166], product.productId);
            var sliderIsFullHeight = _.includes([165, 166], product.productId);
            var dualSlider = _.includes([164, 166], product.productId);
            var sliderWidth = customData.sliderWidth || (product.dimensions.width / 2);
            var dualSliderOffset = product.dimensions.width / 7;
            var sliderAvailableLeftOffsetDistance = product.dimensions.width - sliderWidth - (dualSlider ? dualSliderOffset : 0);
            var sliderOffsetsArray = [0.3, 0.7, 0.5];
            var sliderLeftOffsetPosition = 0;

            _.forEach(shelfSpacingsArray, (shelfHeight, shelfIndex) => {
              var shelfBehindSlider = sliderIsFullHeight ? (!(shelfIndex === 0 || shelfIndex === shelfSpacingsArray.length - 1)) : (false);
              var shelfDepth = shelfBehindSlider ? product.dimensions.depth - 1.5 : product.dimensions.depth - 0.75;

              nodes.push({
                nodeProps: {
                  position: {...position3d, y: position3d.y + shelfHeight, z: position3d.z + 0.75},
                  dimensions: {...product.dimensions, height: shelfWidth, depth: shelfDepth},
                  rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                }
              });
            });

            _.forEach(shelfSpacingsArray, (shelfHeight, shelfIndex) => {
              var partitionTopPosition = shelfSpacingsArray[shelfIndex - 1] + shelfWidth;
              var partitionHeight = shelfHeight - partitionTopPosition;
              var partitionProps = {top: partitionTopPosition, width: partitionWidth, height: shelfOffsets[shelfIndex - 1], isHatchFillable: true, hatchKey: 'box'};
              sliderLeftOffsetPosition = sliderOffsetsArray[shelfIndex] * sliderAvailableLeftOffsetDistance;
              var partitionDepth = isOpen ? product.dimensions.depth - 0.75 : product.dimensions.depth - 1.5;

              if (sliderLeftOffsetPosition && partitionTopPosition) {
                if (hasPartitions) {
                  if (shelfIndex % 2 !== 0 && shelfIndex !== 0) {
                    nodes.push({
                      nodeProps: {
                        position: {x: position3d.x + 12, y: position3d.y + partitionTopPosition, z: position3d.z + 0.75},
                        dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                        rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                      }
                    },
                    {
                      nodeProps: {
                        position: {x: position3d.x + (product.dimensions.width -  12), y: position3d.y + partitionTopPosition, z: position3d.z + .75},
                        dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                        rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                      }
                    });

                    if (product.dimensions.width >= 60) {
                      nodes.push({
                        nodeProps: {
                          position: {x: position3d.x + (product.dimensions.width / 2), y: position3d.y + partitionTopPosition, z: position3d.z + .75},
                          dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                          rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                        }
                      });
                    }
                  }
                  else {
                    if (product.dimensions.width >= 60) {
                      nodes.push({
                        nodeProps: {
                          position: {x: position3d.x + (product.dimensions.width / 3), y: position3d.y + partitionTopPosition, z: position3d.z + 0.75},
                          dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                          rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                        }
                      },
                      {
                        nodeProps: {
                          position: {x: position3d.x + (product.dimensions.width / 3 * 2), y: position3d.y + partitionTopPosition, z: position3d.z + 0.75},
                          dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                          rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                        }
                      });
                    }
                    else {
                      nodes.push({
                        nodeProps: {
                          position: {x: position3d.x + (product.dimensions.width / 2), y: position3d.y + partitionTopPosition, z: position3d.z + 0.75},
                          dimensions: {width: partitionWidth, height: shelfOffsets[shelfIndex - 1], depth: partitionDepth},
                          rotation: 0, fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
                        }
                      });
                    }
                  }
                }

                if (hasSlider && !sliderIsFullHeight && shelfIndex !== 0) {
                  //TODO slider
                  slider({
                    x: sliderLeftOffsetPosition,
                    y: partitionTopPosition,
                    width: sliderWidth,
                    height: shelfOffsets[shelfIndex - 1],
                    dualSlider,
                    dualSliderOffset
                  })
                }
              }
            });

            if (hasSlider && sliderIsFullHeight) {
              slider({
                x: sliderLeftOffsetPosition,
                y: product.dimensions.height - (_.sum(shelfOffsets) + (shelfWidth * shelfOffsets.length)),
                height: _.sum(shelfOffsets) + (shelfWidth * shelfOffsets.length - 1),
                width: sliderWidth,
                dualSlider,
                dualSliderOffset,
                sliderIsFullHeight
              })
            }

            nodes.push({
              nodeProps: {
                position: position3d,
                dimensions: {...product.dimensions, depth: 0.75},
                rotation: 0, fill: _.get(productHatchFillData.hatchFills, `front`) || productFill, opacity: 1
              }
            });
          }
          else if (isStandaloneAppliance) {
            nodes.push({
              nodeProps: {
                position: position3d,
                dimensions: product.dimensions,
                rotation: 0, fill: K.applianceAppearancePropsByTheme().fill.light, opacity: 1
              }
            });
          }
          else if (hasBox) {
            nodes.push({
              nodeProps: {
                position: position3d,
                dimensions: {...productDims, depth: productDims.depth - 0.75},
                rotation: 0,
                fill: _.get(productHatchFillData.hatchFills, `box`) || productFill, opacity: 1
              }
            });
            nodes.push({
              nodeProps: {
                position: {...position3d, z: position3d.z + (productDims.depth - 0.75)},
                dimensions: {...productDims, depth: 0.75},
                rotation: 0,
                fill: _.get(productHatchFillData.hatchFills, `front`) || productFill, opacity: 1
              }
            });
          }
          else {
            nodes.push({
              nodeProps: {
                position: position3d,
                dimensions: product.dimensions,
                rotation: 0, fill: productFill, opacity: 1
              }
            });
          }
        });
      }
    }

    return nodes;
  }
};

export default Container3dNodesHelper;
