import _ from 'lodash';
import K from 'k';

import lib from 'lib';
import Project from 'project-helpers/project';
import Product from 'project-helpers/product';
import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import Floor from 'project-helpers/floor';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container';
import Drawings from 'project-helpers/drawings';
import Box3dView from 'ui/components/box-3d/box-3d';

import { resourceActions, connect } from 'redux/index.js';
import CanvasProductHelper from 'project-component-helpers/canvas-product-helper';

var MaterialsAndAppliancesDrawingsDataHelper = {
  getAppliancesData({project}) {
    var {rooms, floors} = Project.get(['rooms', 'floors'], {project});
    var companyKey = project.companyKey;
    var companyName = Project.getCompanyName({project});
    let appliances = Drawings.getAppliances({project});

    let applianceData = {};

    if (appliances.length > 0) {
      _.forEach(_.sortBy(floors, 'rank'), floor => {
        let rooms = Floor.get('rooms', {floor});

        _.forEach(_.sortBy(rooms, 'rank'), room => {
          var roomAppliances = _.filter(appliances, appliance => {
            var {context} = appliance;
            var instanceRoom = (context === 'product' ? Product : Container).get('room', {[context]: appliance.instance});

            return instanceRoom.id === room.id;
          });

          if (roomAppliances.length) {
            var elevations = Room.get('sortedElevations', {room});

            var accountedForInstances = [];

            _.forEach(elevations, elevation => {
              var {containers, products} = Elevation.get(['containers', 'products'], {elevation});

              var appliancesInElevation = _.filter(roomAppliances, appliance => {
                return !_.includes(accountedForInstances, `${appliance.instance.id}${appliance.context}`) && (appliance.context === 'product' ? _.find(products, {id: appliance.instance.id}) : _.find(containers, {id: appliance.instance.id}));
              });

              var orderedAppliancesInElevation = _.sortBy(appliancesInElevation, appliance => {
                var index = ['refrigerator', ''].indexOf(appliance.type);

                return index !== -1 ? -10000 : (appliance.context === 'product' ? Product.getWallprint({product: appliance.instance, elevation})[0].x : Container.getWallprintInElevation({container: appliance.instance, elevation})[0].x);
              });

              _.forEach(orderedAppliancesInElevation, ({title, modelNumber, vendor, instance, customModelNumber, customVendor, context, ...rest}) => {
                vendor = customVendor || vendor;
                modelNumber = customModelNumber || modelNumber;

                var applianceFit = context === 'product' ? Product.getApplianceFit({product: instance}) : undefined;

                var dbProductApplianceType = context === 'product' ? Product.getApplianceType({product: instance}) : undefined;

                let productElgibleForPullPanelByX = applianceFit === 'fitted';
                var pullPanelByXCopy = '';

                if (_.includes([748, 1223, 1224, 747, 746], instance.productId)) productElgibleForPullPanelByX = false;

                if (productElgibleForPullPanelByX) {
                  var applianceTypesWithPullPanelByX = ['refrigerator', 'dishwasher'];

                  if (_.includes(applianceTypesWithPullPanelByX, dbProductApplianceType)) {
                    pullPanelByXCopy = `pulls and panel by ${companyName}`;

                    var pullId = _.get(instance, 'details.pullType.id');
                    var isHBPullByOthersOrNoPulls = companyKey === 'hb' && _.includes([18, 21], pullId);
                    var isSTPullByOthersOrNoPulls = companyKey === 'vp' && _.includes([6, 9], pullId);

                    if (pullId && (isHBPullByOthersOrNoPulls || isSTPullByOthersOrNoPulls)) {
                      pullPanelByXCopy = `panel by ${companyName}`;
                    }
                  }
                }

                var labelData = [];

                // brand + model number includes tbd or is blank, just show tbd, otherwise show as it was
                labelData.push(
                  !_.includes(_.lowerCase(vendor), 'tbd') ? _.startCase(vendor) : 'TBD',
                  !_.includes(_.lowerCase(modelNumber), 'tbd') ? modelNumber : 'TBD',
                  pullPanelByXCopy
                )

                labelData = _.compact(_.uniq(labelData));

                var label = _.join(labelData, ' / ');

                if (applianceData[room.id]) {
                  applianceData[room.id].push({title, modelNumber, vendor, instance, customModelNumber, customVendor, label, ...rest});
                }
                else {
                  applianceData[room.id] = [{title, modelNumber, vendor, instance, customModelNumber, customVendor, label, ...rest}];
                }

                accountedForInstances.push(`${instance.id}${context}`);
              });
            });
          }
        });
      });
    }

    return applianceData;
  },

  //HINT this includes both internal and external Lighting
  //the getHasLighting helper is only for external lighting
  getLightingProducts({products}) {
    var lightingProducts = [];

    _.forEach(products, product => {
      var hasExternalLighting = Product.getHasLighting({product});

      var {productType, productOptionInstances} = Product.get(['productOptionInstances', 'productType'], {product});
      var hasInternalLightingOption = _.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 78);
      var hasBuiltInInternalLighting = productType.category === 73 || _.includes([605, 606], product.productId);
      var hasInternalLighting = hasInternalLightingOption || hasBuiltInInternalLighting;

      if (hasExternalLighting || hasInternalLighting) {
        lightingProducts.push(product);
      }
    });

    return lightingProducts;
  },

  getSinkCutoutProducts({products}) {
    var sinkCutoutProducts = [];

    _.forEach(products, product => {
      let productOptionInstances = Product.get('productOptionInstances', {product});

      if (_.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 7)) {
        sinkCutoutProducts.push(product);
      }
    });

    return sinkCutoutProducts;
  },

  getDrainFieldProducts({products}) {
    var drainFieldProducts = [];

    _.forEach(products, product => {
      let productOptionInstances = Product.get('productOptionInstances', {product});

      if (_.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 8)) {
        drainFieldProducts.push(product);
      }
    });

    return drainFieldProducts;
  },

  getCountertopProducts({products}) {
    var countertopProducts = [];

    _.map(products, product => {
      if (_.get(Product.getProductData({product}), 'categoryId') === 46) {
        countertopProducts.push(product);
      }
    });

    countertopProducts = _.uniq(_.compact(countertopProducts));

    //HINT sort by productionId
    countertopProducts = _.sortBy(countertopProducts, [instance => instance.productionId ? 0 : 1, instance => _.get(instance, 'productionId[0]'), instance => instance.productionId ? _.toNumber(_.split(instance.productionId, instance.productionId[0])[1]) : 0])
    return countertopProducts;
  },

  getAppliancesByRooms({rooms, appliancesData}) {
    var appliancesByRooms = [];

    _.forEach(rooms, (room) => {
      if (_.has(appliancesData, room.id)) {
        var roomAppliances = _.get(appliancesData, room.id);
        var duplicateRoomAppliancesIndex = -1;
        var appliancesByRoomIdentifiersArray = _.sortBy(_.map(roomAppliances, appliance => ({identifier: `${appliance.applianceType}-${appliance.label}`})), ['identifier']);

        _.forEach(appliancesByRooms, (appliancesByRoom, index) => {
          var existingAppliancesByRoomsIdentifiersArray = _.sortBy(_.map(appliancesByRoom.appliances[0], appliance => ({identifier: `${appliance.applianceType}-${appliance.label}`})), ['identifier']);

          if (JSON.stringify(appliancesByRoomIdentifiersArray) === JSON.stringify(existingAppliancesByRoomsIdentifiersArray)) {
            duplicateRoomAppliancesIndex = index;
          }
        });

        if (duplicateRoomAppliancesIndex !== -1) {
          var updatedAppliancesByRoomData = appliancesByRooms[duplicateRoomAppliancesIndex];
          _.set(updatedAppliancesByRoomData, 'roomIds', [...updatedAppliancesByRoomData.roomIds, room.id]);

          appliancesByRooms[duplicateRoomAppliancesIndex] = updatedAppliancesByRoomData;
        }
        else {
          appliancesByRooms.push({roomIds: [room.id], appliances: [roomAppliances]});
        }
      }
    })

    return appliancesByRooms
  },

  getApplications({products}) {
    var applications = [];

    _.forEach(products, productInstance => {
      var {container, dependencies, productOptionInstances} = Product.get(['container', 'dependencies', 'productOptionInstances'], {product: productInstance});
      let ownedCompatibleDetails = Product.getOwnedCompatibleDetails({product: productInstance, renderForDrawings: true});
      var subproductData = Product.getSubproductData({product: productInstance});
      var hasAppliedBrass = (_.get(productInstance, 'customData.productOptions.80.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.81.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.82.enabled')) === 1 ||
        (_.get(productInstance, 'customData.productOptions.83.enabled')) === 1;

      var hasWoodDrawers = _.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 1);

      var {details} = productInstance;
      var compatibleDetailGroups = [];

      ownedCompatibleDetails = _.reject(ownedCompatibleDetails, detail => {
        var isSubproductDetail = _.split(detail.key, '-').length > 1;
        var highLevelKey = isSubproductDetail ? _.split(detail.key, '-')[2] : detail.key;

        return _.includes(['pullLocation', 'yPullLocation', 'grainDirection', 'grainContinuity', 'leafDoorsCentered', 'frontPanelType'], highLevelKey);
      });

      if (hasWoodDrawers && !_.some(ownedCompatibleDetails, detail => _.includes(['drawerMaterial', 'woodDrawerMaterial'], detail.key))) {
        if (container.customData.hasWoodDrawers && _.get(container, 'details.drawerMaterial.id')) {
          var materialClasses = _.values(dependencies.materialClasses.byId);
          var flattenedMaterials = _.flatMap(materialClasses, materialClass => materialClass.materials);

          var material = _.find(flattenedMaterials, {id: container.details.drawerMaterial.id});

          if (material) {
            ownedCompatibleDetails.push({key: 'drawerMaterial', type: 'material', title: 'Wood Drawer Material', options: [
              {id: material.id, title: material.title}
            ]});
          }
        }
      }

      _.forEach(ownedCompatibleDetails, ownedCompatibleDetail => {
        if (hasAppliedBrass && ownedCompatibleDetail.key === 'frontMaterial') {
          compatibleDetailGroups.push([{key: 'brassFrontMaterial', type: 'material', title: 'Front', options: [{id: 299, title: 'Applied Brass'}]}, ownedCompatibleDetail]);
        }
        else if (!_.includes(ownedCompatibleDetail.key, 'pull')) {
          compatibleDetailGroups.push([ownedCompatibleDetail]);
        }
      });

      if (subproductData && subproductData.length > 0) {
        _.forEach(subproductData, (data, index) => {
          _.times(data.quantity, n => {
            var pullTypeDetail = _.find(ownedCompatibleDetails, {key: `${data.type}-${n}-pullType`});
            var pullMaterialDetail = _.find(ownedCompatibleDetails, {key: `${data.type}-${n}-pullMaterial`});

            if (pullTypeDetail && pullMaterialDetail) {
              compatibleDetailGroups.push([pullTypeDetail, pullMaterialDetail]);
            }
            else if (pullTypeDetail && !pullMaterialDetail) {
              compatibleDetailGroups.push([pullTypeDetail]);
            }
          });
        });
      }
      else {
        var pullTypeDetail = _.find(ownedCompatibleDetails, {key: 'pullType'});
        var pullMaterialDetail = _.find(ownedCompatibleDetails, {key: 'pullMaterial'});

        if (pullTypeDetail && pullMaterialDetail) {
          compatibleDetailGroups.push([pullTypeDetail, pullMaterialDetail]);
        }
        else if (pullTypeDetail && !pullMaterialDetail) {
          compatibleDetailGroups.push([pullTypeDetail]);
        }
      }

      _.forEach(compatibleDetailGroups, (compatibleDetails) => {
        var appliedDetails = _.map(compatibleDetails, compatibleDetail => {
          var isSubproductDetail = _.split(compatibleDetail.key, '-').length > 1;
          var value = details[compatibleDetail.key];

          if (!value) {
            if (isSubproductDetail) {
              var subproductDetailKey = _.split(compatibleDetail.key, '-')[2];

              var parentValueIsValid = details[subproductDetailKey] && _.find(compatibleDetail.options, {id: details[subproductDetailKey].id});

              value = parentValueIsValid ? details[subproductDetailKey] : {id: _.get(compatibleDetail, 'options')[0].id};
            }
            else {
              value = {id: _.get(compatibleDetail, 'options')[0].id};
            }
          }

          var activeDetailOption = _.find(_.get(compatibleDetail, 'options'), {id: value.id});
          var title = _.get(activeDetailOption, 'title', '');

          return {key: isSubproductDetail ? _.split(compatibleDetail.key, '-')[2] : compatibleDetail.key, value, compatibleDetail: {...compatibleDetail, title: compatibleDetail.title.replace(compatibleDetail.titlePrefix, '')}, title};
        });

        if (_.every(appliedDetails, ({value}) => value !== undefined)) {
          var appliedDetailsForComparison = _.map(appliedDetails, appliedDetail => {

            return {key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.compatibleDetail.title};
          });

          let application = _.find(applications, application => {
            var applicationForComparison = _.map(application.appliedDetails, appliedDetail => ({key: appliedDetail.key, value: appliedDetail.value.id, title: appliedDetail.compatibleDetail.title}));

            return _.isEqual(applicationForComparison, appliedDetailsForComparison);
          });

          if (!application) {
            application = {appliedDetails, productInstances: []};

            applications.push(application);
          }

          if (!_.includes(application.productInstances, productInstance)) {
            application.productInstances.push(productInstance);
          }
        }
      });
    });

    return applications;
  },

  getAppliedMaterialsAndPulls({applications, materialTypes, companyKey}) {
    var appliedMaterials = [];
    var appliedPulls = [];

    _.forEach(applications, ({appliedDetails, productInstances}) => {
      _.forEach(appliedDetails, (appliedDetail, i) => {
        var {compatibleDetail, key, title, value} = appliedDetail;
        // TODO: add to if: !_.includes(title, 'tbd')
        // TODO: add to if: && not already in appliedMaterials
        if (_.includes(_.toLower(key), 'material')
        && !_.includes(_.toLower(key), 'pull')) { //prevents pullMaterial which is separate
          var option = _.find(compatibleDetail.options, ['id', value.id]) || _.find(compatibleDetail.options, {value}) || _.get(compatibleDetail, 'options[0]');
          var materialId = option.id;

          var materialIndex = _.findIndex(_.flatMapDeep(appliedMaterials), ({material, secondaryMaterialId}) => {
            return material.id === materialId && ((i === 0 && appliedDetails.length > 1) ? secondaryMaterialId === appliedDetails[1].value.id : true);
          });

          if (materialIndex !== -1) {
            appliedMaterials[materialIndex].applications.push({appliedDetail, productInstances});
            appliedMaterials[materialIndex].totalProductInstances.push(...productInstances);
          }
          else {
            var materialTypeId = _.get(_.find(compatibleDetail.options, ['id', materialId]), 'materialTypeId');

            if (i === 0 && _.some(appliedDetails, {key: 'brassFrontMaterial'})) {
              var baseFrontDetail = appliedDetails[1];

              appliedMaterials.push({
                material: {
                  id: materialId,
                  title: `${_.startCase(_.get(_.find(compatibleDetail.options, ['id', materialId]), 'title'))} on ${_.startCase(_.get(_.find(baseFrontDetail.compatibleDetail.options, ['id', baseFrontDetail.value.id]), 'title'))}`,
                  thumbnail: 'https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/299.jpg',
                },
                applications: [{appliedDetail, productInstances}],
                totalProductInstances: productInstances,
                secondaryMaterialId: baseFrontDetail.value.id
              });
            }
            else {
              appliedMaterials.push({
                material: {
                  id: materialId,
                  title: _.startCase(_.get(option, 'title')),
                  thumbnail: _.get(option, 'thumbnail', `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/material_swatches/${materialId}.jpg`),
                },
                applications: [{appliedDetail, productInstances}],
                totalProductInstances: productInstances
              });
            }
          }
        }
        else if (_.includes(_.toLower(key), 'pull')) {
          var pullTypeAppliedDetails = _.find(appliedDetails, ['key', 'pullType']);
          var pullMaterialAppliedDetails = _.find(appliedDetails, ['key', 'pullMaterial']);

          var pullIndex = -1;

          pullIndex = _.findIndex(appliedPulls, (appliedPull) => {
            return appliedPull.pull.id === pullTypeAppliedDetails.value.id && appliedPull.pullMaterial?.id === _.get(pullMaterialAppliedDetails, 'value.id');
          });

          if (pullIndex !== -1) {
            appliedPulls[pullIndex].applications.push({appliedDetail, pullMaterialAppliedDetails, productInstances});
            appliedPulls[pullIndex].totalProductInstances.push(...productInstances);
          }
          else {
            var pullTypeOptions = [
              {id: 1, title: 'Radius Staple'},
              {id: 2, title: 'Radius Flat Tab'},
              {id: 8, title: 'Wire Radius Staple'},
              {id: 3, title: 'Knurled Knob'},
              {id: 4, title: 'Curved Knob'},
              {id: 5, title: 'Notched Knob'},
              {id: 7, title: 'Touch Latch'},
              {id: 6, title: 'No Pulls'},
              {id: 9, title: 'Pulls by Others'},
              {id: 10, title: 'Scooped Bottom'}
            ];

            appliedPulls.push({
              pull: {
                id: pullTypeAppliedDetails.value.id,
                title: companyKey === 'ST'
                  ? _.find(pullTypeOptions, ['id', value.id]).title
                  : _.startCase(_.get(_.find(pullTypeAppliedDetails.compatibleDetail.options, ['id', value.id]), 'title')),
                // thumbnail: `https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files/pricing_tool/product_icons/${}?crossorigin` //TODO: find how to get pics
              },
              pullMaterial: pullMaterialAppliedDetails ? {
                id: pullMaterialAppliedDetails.value.id,
                title: _.startCase(_.get(_.find(pullMaterialAppliedDetails.compatibleDetail.options, ['id', pullMaterialAppliedDetails.value.id]), 'title'))
              } : undefined,
              applications: [{appliedDetail, pullMaterialAppliedDetails, productInstances}],
              totalProductInstances: productInstances
            });
          }
        }
      });
    });

    return {appliedMaterials, appliedPulls};
  },

  getFilteredProducts({products}) {
    var filteredProducts = _.cloneDeep(products);

    //HINT filter out bundles/accessories for ST
    filteredProducts = _.filter(filteredProducts, product => {
      var {productType} = Product.get(['productType'], {product});
      var productCategoryId = _.get(productType, 'categoryId');

      return productCategoryId !== 81 && !_.includes([1624], product.productId) && _.includes(['containerInstance', 'productInstance'], product.primaryAssociationKey);
    });

    return filteredProducts;
  },
}

export default MaterialsAndAppliancesDrawingsDataHelper
