import React, {useState, memo } from 'react';

import K from 'k';
import _ from 'lodash';
import lib from 'lib';

import CanvasView from 'canvas/canvas-view.js'
import CanvasContainer from 'project-components/canvas-container';
import CanvasScriptObject from 'canvas/canvas-script-object';
import InfoIcon from '../../../../assets/info-icon.png';

import Container from 'project-helpers/container';

var thumbnailDiameter = 90;

export default function Thumbnail({title, thumbnail, style, showAccessoriesView, item, optionIndex, isOrnament, isSpeccedContainer, isDisabled, thumbnailScript, companyKey}) {
  //TODO: if (addingGroup === 'container') show canvasScriptObject like isOrnament is doing. ISSUE: finding an elevation for the canvasScriptObject
  var canvasScale = _.get(item, 'thumbnailData.canvasScale', 1);
  var offset = _.get(item, 'thumbnailData.offset', {x: 0, y: 0});

  thumbnailDiameter = isSpeccedContainer ? 180 : 90;

  var scriptContainerSize = {
    width: Math.max(thumbnailDiameter, _.get(item, 'resourceProps.dimensions.width') || _.get(item, 'props.dimensions.width')),
    height: Math.max(thumbnailDiameter, _.get(item, 'resourceProps.dimensions.height') || _.get(item, 'props.dimensions.height'))
  };

  if (isSpeccedContainer) {
    canvasScale = (thumbnailDiameter - 45) / _.max([_.get(item, 'resourceProps.dimensions.width'), _.get(item, 'resourceProps.dimensions.height')]);
  }

  return (
    <div className={`add-menu-thumbnail${isDisabled ? '-static' : ''}`} style={{marginLeft: 'auto', marginRight: 'auto', width: thumbnailDiameter, height: thumbnailDiameter, flexShrink: 0, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', ...style}}>
      {(isSpeccedContainer) ? (
        <CanvasView
          viewMode={'front'}
          className={`add-button-ornament-view-${item.id}`}
          style={{width: '100%', height: '100%', backgroundColor: 'transparent'}}
          scale={canvasScale}
          containerSize={scriptContainerSize}
          projectData = {{
            companyKey
            // ..._.pick(project, ['dimensionsData', 'companyKey', 'versionId', 'id', 'isEmployee']),
            // activeDimensionsLayer,
            // isEditingDimensions: false,
            // activeProjectGraphicsLayer,
            // countertopsAreSelectable: false,
            // pushToUndoQueue: null,
            // toggleTolerancePopupShowing: false,
            // showingAlignmentIndicators: false,
          }}
          isStatic
        >
          <CanvasContainer
            //HINT bug where thumbnails didn't update when changing viewmodes
            key={`${optionIndex}-${_.get(item, 'id', lib.string.uuid())}`}
            isNonSpacial
            nonSpacialSideKey={'front'}
            overridePosition={lib.object.sum({x: -item.resourceProps.dimensions.width / 2, y: item.resourceProps.dimensions.height / 2, z: 0}, offset)}
            {...{viewKey: 'front', activeDetailLevel: 'fullDetail'}}
            container={item.resourceProps}
          />
        </CanvasView>
      ) : ((isOrnament) ? (
          <CanvasView
            viewMode={'front'}
            className={`add-button-ornament-view-${item.id}`}
            style={{width: '100%', height: '100%', backgroundColor: 'transparent'}}
            scale={canvasScale}
            containerSize={scriptContainerSize}
            isStatic
          >
            <CanvasScriptObject
              //HINT bug where thumbnails didn't update when changing viewmodes
              key={`${optionIndex}-${_.get(item, 'id', lib.string.uuid())}`}
              metaProps={{props: {...item.resourceProps}}}
              script={thumbnailScript}
              rotation={0}
              fill={''}
              strokeWidth={0.25}
              isDisabled={isDisabled}
              {...{stroke: 'black', position: lib.object.sum({x: -item.resourceProps.dimensions.width / 2, y: 0, z: 0}, offset), size: item.resourceProps.dimensions, isSelected: false, multipleEntitiesSelected: false, viewKey: 'front', disabledAnchors: true, snapToLines: false, locked: true, isRotatable: false, isScalable: true, hatchFill: 'white', hatchFills: {}, renderForDrawings: false}}
            />
          </CanvasView>
        ) : (
          <img
            src={thumbnail}
            alt={title}
            style={showAccessoriesView
              ? {width: '100%', height: '100%', objectFit: 'cover'}
              : {width: '65%', height: '65%', objectFit: 'contain'}
            }
          />
        )
      )}
    </div>
  );
}
