import React, {useState, useRef, useEffect} from 'react';

import Drawings from 'project-helpers/drawings';

import _ from 'lodash';

const Draggable = ({children, containerDimensions, isEditing, project, mode, visibilityLayers, activeDimensionsLayer, activeProjectGraphicsLayer, detailLevel, fillMode, moveGraphic, id, size, title, isContext, isEmpty, position, scale = 1, type, model, indicators, onReplaceClick, onDelete, imageMode}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [translateGraphicFrom, setTranslateGraphicFrom] = useState();
  const [translateGraphicTo, setTranslateGraphicTo] = useState();

  const canvasSize = isEmpty ? lib.object.multiply(containerDimensions, 1 / 6) : Drawings.getSizeForPrintArea({printArea: {contentSize: size}, scale, project});
  const positionInPage = Drawings.getPositionInPage({position, size: canvasSize, containerDimensions});

  const translateGraphicToRef = useRef();


  useEffect(() => {
    window.addEventListener('mousemove', (handleMouseMove));
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
  }, [isDragging]);

  const handleMouseDown = (event) => {
    if (event.button === 2) return;

    if (isEditing && !_.includes(event.target.className, 'prevent-drag')) {
      var { clientX, clientY } = event;
      setIsDragging(true);
      translateGraphicToRef.current = {x: clientX, y: clientY};
      setTranslateGraphicFrom({x: clientX, y: clientY});
    }
  };

  const handleMouseMove = ({clientX, clientY}) => {
    if (isDragging) {
      //constrain graphic x/y

      var attemptedDelta = {
        x: clientX - translateGraphicFrom.x,
        y: clientY - translateGraphicFrom.y
      }

      var attemptedLeft = Math.round(positionInPage.left + attemptedDelta.x);
      var attemptedTop = Math.round(positionInPage.top + attemptedDelta.y);

      var pageDimensions = containerDimensions;

      if (document.getElementsByClassName('drawings-page-main-content').length > 0) {
        var containerDiv = document.getElementsByClassName('drawings-page-main-content')[0];

        pageDimensions = {width: containerDiv.offsetWidth, height: containerDiv.offsetHeight};
      }

      //hint negative padding as pixel value
      var extendingOffLeft = attemptedLeft < -25.6;
      var extendingOffRight = attemptedLeft + canvasSize.width > pageDimensions.width - 25.6;
      var extendingOffTop = attemptedTop < -25.6;
      var extendingOffBottom = attemptedTop + canvasSize.height > pageDimensions.height - 25.6;

      var isExtendingOffPage = extendingOffLeft || extendingOffRight || extendingOffTop || extendingOffBottom;

      if (extendingOffLeft) clientX = -25.6 + translateGraphicFrom.x - positionInPage.left;
      if (extendingOffRight) clientX = pageDimensions.width - canvasSize.width - 25.6 + translateGraphicFrom.x - positionInPage.left;
      if (extendingOffTop) clientY = -25.6 + translateGraphicFrom.y - positionInPage.top;
      if (extendingOffBottom) clientY = pageDimensions.height - 25.6 - canvasSize.height + translateGraphicFrom.y - positionInPage.top;

      translateGraphicToRef.current = {x: clientX, y: clientY};
      setTranslateGraphicTo({x: clientX, y: clientY});
    }
  }

  const handleMouseUp = async () => {
    if (isDragging && translateGraphicToRef.current && translateGraphicFrom) {
      var delta = {
        x: translateGraphicToRef.current.x - translateGraphicFrom.x,
        y: translateGraphicToRef.current.y - translateGraphicFrom.y
      }

      if (delta.x !== 0 || delta.y !== 0) {
        var left = Math.round(positionInPage.left + delta.x);
        var top = Math.round(positionInPage.top + delta.y);

        moveGraphic(id, left, top);
      }

      setIsDragging(false);
    }
  }

  return (<div onMouseDown={handleMouseDown} style={{
    transform: `translate(${isDragging && translateGraphicTo ? translateGraphicTo.x - translateGraphicFrom.x : 0}px, ${isDragging && translateGraphicTo ? translateGraphicTo.y - translateGraphicFrom.y : 0}px)`
  }}>{React.cloneElement(children, {isDragging})}</div>);
}

export default Draggable
