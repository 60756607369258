import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import K from 'k';
import lib from 'lib';

import checkIconBlack from 'assets/check-icon-black.png';
import Thumbnail from './thumbnail';
import Slideshow from 'components/slideshow';

function ConfiguratorOptionSelector({project, archetype, activePropertyData, appliedOption = {}, onBackClick, onApplyClick, isConfirming, hasConfirmed, handleBuyButtonClick, pulls}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeSlideshowOption, setActiveSlideshowOption] = useState(null);
  const [mediaByType, setMediaByType] = useState({mediaByMaterial: {}, mediaByPull: {}});

  var style = {
    header: {
      column: {flex: 1, display: 'flex', flexDirection: 'column'}
    },
    fonts: {
      label: {fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em'}
    },
  };

  var {options, title: propertyTitle, archetypeParameterGroup, path} = activePropertyData;
  var {value: appliedOptionId} = appliedOption;

  var handleOnClick = (option) => () => {
    setSelectedOption(option);
    handleOnApply({selectedOption: option});
    setActiveSlideshowOption(option);
  };
  var handleSelectPhotosClick = (option) => () => setActiveSlideshowOption(option);

  var handleOnApply = ({selectedOption}) => onApplyClick({id: activePropertyData.id, value: selectedOption.value, path, activePropertyData, appliedOption: selectedOption});

  useEffect(async () => {
    var mediaByTypeData = {mediaByMaterials: {}, mediaByPull: {}};

    if (activePropertyData.path === 'pullType') {
      mediaByTypeData.mediaByPulls = _.mapValues(pulls, (pull, pullIndex) => {
        return _.map(pull.media, medium => ({...medium, mediumId: medium.id, id: pullIndex}));
      });
    }
    else {
      var materialOptionIds = _.map(options, _o => {
        mediaByTypeData.mediaByMaterials[_o.value] = [];

        return _o.value;
      });

      var media = await lib.api.get('media', {where: {materialId: materialOptionIds, companyKey: 'hb', isPublic: 1}, order: ['rank']});

      _.forEach(media, (medium, mediumIndex) => {
        _.forEach(materialOptionIds, (id, index) => {
          if (_.get(medium, `associations.materials.id_${id}`)) {
            mediaByTypeData.mediaByMaterials[id].push({...medium, mediumId: medium.id, id: mediumIndex});
          }
        });
      });
    }

    setMediaByType(mediaByTypeData);

  }, []);

  return (
    <div style={{backgroundColor: 'white', display: 'flex', minWidth: '100%', justifyContent: 'space-between'}}>
      <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '2.5rem', paddingTop: '2rem', paddingBottom: '2rem', height: '100%', width: '50%', minHeight: 0, overflowY: 'scroll', borderRight: 'rgba(0, 0, 0, 0.05) 1px solid'}}>
        <div style={{...style.fonts.label, ...style.header.column, marginBottom: '7rem'}}>
          <div style={{letterSpacing: '0.15em', fontSize: '1.25em', fontWeight: 400, textTransform: 'uppercase', opacity: 0.5}}>
            {project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'}{archetype && archetype.type === 'atlanticFields' ? ' & Atlantic Fields' : ''}
          </div>
          <div style={{marginTop: '0.5rem', marginBottom: '1.5rem', fontSize: '1.5em', letterSpacing: '0.06em'}}>
            {project.title || `For ${project.clientName}`}{!!project.locked && ' - Finalized By Client'}
          </div>
          {!project.locked && !isConfirming && !hasConfirmed && <div><div style={{width: 'fit-content', fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '0.85em', cursor: 'pointer', backgroundColor: 'black', color: 'white', padding: '0.5rem 1rem 0.5rem'}} onClick={handleBuyButtonClick}>FINALIZE</div></div>}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0}}>
          <div style={{display: 'flex'}}>
            <div style={{fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '1em', opacity: 0.5, marginBottom: 10, cursor: 'pointer'}} onClick={onBackClick}>Back</div>
          </div>
          <div style={{display: 'flex', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '1.2em', marginBottom: 25}}>{archetypeParameterGroup.title} {'>'} {propertyTitle}</div>
          {
            _.map(options, o => (
              <div key={`option-${o.value}`} style={{display: 'flex', marginBottom: K.spacing * 2, width: '22rem', alignItems: 'center'}}>
                <div style={{display: 'flex', flex: 1, columnGap: K.spacing * 2, alignItems: 'center', cursor: 'pointer'}} onClick={handleOnClick(o)}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, width: 50, minWidth: 50, borderRadius: 25, border: K.grayBorder, overflow: 'hidden', position: 'relative'}}>
                    {_.get(selectedOption, 'value') === o.value ? <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img style={{width: 30, height: 30, filter: 'invert(100%)'}} src={checkIconBlack}/></div> : null}
                    <Thumbnail key={`option-thunmbnail-${o.value}`} src={o.thumbnail} style={{height: 50, width: 50, minWidth: 50, backgroundColor: 'transparent'}}/>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div
                      style={{marginBottom: 5, opacity: 0.5, textTransform: 'upperCase', fontSize: '0.85em', letterSpacing: '0.075em', fontWeight: appliedOptionId === o.value ? 'bold' : 'normal'}}
                    >
                      {o.title}
                    </div>
                  </div>
                </div>
                {(activePropertyData.type === 'conditionalResources' || (activeSlideshowOption ? activeSlideshowOption.value === o.value : (appliedOption.value === o.value))) ? null : <div onClick={handleSelectPhotosClick(o)}><div style={{opacity: 0.5, fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '0.85em', marginBottom: 10, cursor: 'pointer', backgroundColor: K.colors.gray, padding: '0.5rem 1rem 0.5rem'}}>SEE PHOTOS</div></div>}
              </div>
            ))
          }
        </div>
      </div>
      <div style={{paddingTop: '2rem', paddingBottom: '2rem', marginRight: '2.5rem', width: '50%', height: '100%', alignContent: 'center'}}>
        <div style={{height: 'fit-content', width: 'fit-content', margin: 'auto'}}>
          <Slideshow media={_.get(mediaByType, `${activePropertyData.path === 'pullType' ? 'mediaByPulls' : 'mediaByMaterials'}.${activeSlideshowOption ? activeSlideshowOption.value : appliedOptionId}`, [])} onClose={() => console.log('onClose called')} imageStyle={{width: 500, height: 500}} closeDisabled/>
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorOptionSelector;