import _ from 'lodash';
import React, {useState, useRef} from 'react';
import K from '../k';
import useClickOutside from '../hooks/click-outside';
import upArrowBlack from '../assets/up-arrow-black.png';
// import Icon from './icon';

const Dropdown = ({label, options = [], style, contentContainerStyle, labelStyle, value, collapseOnChange = true, isControlled=false, disabled = false, onChange, right, hasFixedHeight, placeholder, isCollapsedDefault = true, showHeader = true, isLiteMode = false, onCollapse, showCaret = true}) => {
  const wrapperRef = useRef(null);

  isControlled = isControlled || value !== undefined;
  const [selectedValue, setSelectedValue] = isControlled ? [value, null] : useState(null);
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedDefault);
  const [hoveringOptionValue, setHoveringOptionValue] = useState(null);

  useClickOutside(wrapperRef, () => {
    if (onCollapse) onCollapse();
    setIsCollapsed?.(true)
  });

  const selectedIndex = _.findIndex(options, {value: selectedValue});
  const selected = selectedIndex !== -1 ? options[selectedIndex] : {};

  const lastOptionIsSelected = selectedIndex === options.length - 1;

  const styles = {
    container: {
      position: 'relative',
      userSelect: 'none',
      ...style
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'fit-content',
    },
    dropdownLabel: {
      ...(labelStyle ? {...labelStyle} : {
        ...K.fonts.label,
        lineHeight: 'auto',
      })
    },
    icon: {
      width: 6.07,
      height: 12.35,
      transform: isCollapsed ? 'rotate(90deg)' : 'rotate(180deg)',
      margin: `0 ${!right ? K.spacing : '0'}px 0 ${right ? K.spacing : '0'}px`,
    },
    listItem: ({isSelected, isHovering}) => ({
      ...K.fonts.standard,
      display: 'block',
      padding: '4px 5px 4px 5px',
      borderRadius: lastOptionIsSelected ? '0px 0px 7px 7px' : 0,
      ...(isSelected ? {
        fontWeight: 'bold',
        backgroundColor: K.colors.doubleGray,
      } : {}),
      ...isHovering ? {backgroundColor: 'rgb(245, 245, 245)'} : {}
    }),
    contentContainer: {
      cursor: 'pointer',
      position: 'fixed',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      marginTop: 4,
      backgroundColor: K.colors.base,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '0px 0px 7px 7px',
      width: 'fit-content',
      minWidth: '100px',
      color: 'black',
      userSelect: 'none',
      zIndex: 3,
      maxHeight: hasFixedHeight ? '200px' : undefined,
      overflow: 'auto',
      ...contentContainerStyle
    },
  };

  const toggleCollapsed = () => {
    if (onCollapse) onCollapse();
    setIsCollapsed?.(!isCollapsed);
  }

  const handleOptionSelect = (option) => () => {
    if (!isControlled) setSelectedValue(option.value);

    if (onChange) onChange(option.value || option);

    if (collapseOnChange) toggleCollapsed();
  };

  return (
    <div style={styles.container} ref={wrapperRef}>
      {isLiteMode ? null : showHeader && (<div style={styles.labelContainer} onClick={disabled ? () => {} : toggleCollapsed}>
        {(!right && showCaret) && <img src={upArrowBlack} name='rightArrow' style={styles.icon}/>}
        <span style={styles.dropdownLabel}>{selected.label || selected.title || placeholder || 'Graphics'}</span>
        {(right && showCaret) && <img src={upArrowBlack} name='rightArrow' style={styles.icon}/>}
      </div>)}
      {isLiteMode && <div onClick={disabled ? () => {} : toggleCollapsed} style={styles.dropdownLabel}>{placeholder}</div>}
      {!isCollapsed && (
        <ul style={styles.contentContainer}>
          {_.map(options, (option) => (
            <li
              key={option.value || option.title}
              style={styles.listItem({isSelected: selectedValue === (option.value || option.title), isHovering: hoveringOptionValue === option.value})}
              onClick={handleOptionSelect(option)}
              onMouseEnter={() => setHoveringOptionValue(option.value)}
              onMouseLeave={() => setHoveringOptionValue(null)}
            >
              {option.label || option.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
