import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import Elevation from 'project-helpers/elevation';
import Room from 'project-helpers/room';
import Product from 'project-helpers/product';
import Opencase from 'project-helpers/product-helpers/opencase';
import Barblock from 'project-helpers/product-helpers/barblock';
import Container from 'project-helpers/container';
import addLineToNearestSide from 'dimensions/addLineToNearestSide';

export default function addElevationProductDimensions({allPoints, dimensionSets, outsideDimensionSets, getIsTrapped, elevation, products, productTypes, containers, containersData, room, showProjections, activeDetailLevel}) {
  if (true) {//!isSection
    products = _.filter(products, product => {
      return (!Product.getIsManaged({product}) || _.get(product, 'managedData.managedKey') === 'autofilledStorage') && Product.getSideKey({product, elevation, viewKey: 'front'}) === 'front';
    });

    var productsData = _.orderBy(_.map(products, product => {
      var productType = _.find(productTypes, {id: product.productId});
      var parentProduct = _.find(products, {id: product.productInstanceId});
      var containerData = _.find(containersData, {id: product.containerInstanceId || parentProduct?.containerInstanceId});
      var container = containerData;
      var childProducts = _.filter(products, {productInstanceId: product.id});
      var yInElevation = container.position.y + Container.getDropzoneInset({container, viewKey: 'front'}).y + product.position.y + (parentProduct?.position.y || 0);
      var xInContainer = Container.getDropzoneInset({container, viewKey: 'front'}).x + product.position.x + (parentProduct?.position.x || 0);

      return {...product, containerData, productType, parentProduct, container, childProducts, yInElevation, xInContainer};
    }), ['yInElevation'], ['desc']);
    //TODO track already-accounted-for dimensions - might need to group products by container then sort products by nearest side (bottom/left is ideal if, for example, tall container which is close to both)
    //TODO foreach containers, for each products if product.containerInstanceId === container.id - then sort spatially
    var accountedForDimensionLinesBySizeKey = {width: [], height: []};

    var {companyKey} = Room.get('project', {room});

    _.forEach(productsData, productData => {
      var product = productData;
      var {parentProduct, containerData, productType, container, childProducts, xInContainer} = productData;
      var openUnitReplacesSubcounter = false;
      var subcounterHeight = Container.getSubcounterHeight({container});

      if (
        companyKey === 'vp'
        && Container.getDropzoneSize({container, viewKey: 'front'}).height
        && subcounterHeight === 0.5
        && _.includes([762, 1355, 901, 691], product.productId) // specifically for a few open units
      ) {
        openUnitReplacesSubcounter = true;
      }

      if (_.includes([512, 513, 514], product.productId)) { //HINT assembly dims between shelves
        var {shelfTopOffsets} = product.customData;
        var {dimensions} = product;

        if (shelfTopOffsets) {
          shelfTopOffsets = _.map(_.split(shelfTopOffsets, ','), offset => parseFloat(offset));

          var netOffset = 0;
          var x = dimensions.width * 0.66;

          _.forEach(shelfTopOffsets, (offset, index) => {
            dimensionSets.push({
              type: 'standalone',
              id: `elevation-product-${product.id}-assembly-shelf-${index}-height`,
              alpha: Math.PI / 2,
              offset: 0,
              showLabelBorder: true,
              targets: [
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x, y: netOffset}), id: `elevation-product-${product.id}-assembly-shelf-${index}-height-from`},
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x, y: netOffset + offset}), id: `elevation-product-${product.id}-assembly-shelf-${index}-height-to`}
              ]
            })

            netOffset += offset;
          });
        }
      }

      //HINT pocketing pantry bays
      if (_.includes([1592, 1598], product.productId)) {
        var bottomBayHeight = 30;
        var middleBayHeight = product.customData.middleBayHeight || 36;
        var topBayHeight = _.min([31, product.dimensions.height - middleBayHeight - 30]);
        var productPosition = Product.getPositionInElevation({product, elevation});

        dimensionSets.push({
          type: 'standalone',
          id: `pocketing-pantry-product-${product.id}-top-bay-height`,
          alpha: Math.PI / 2,
          offset: -product.dimensions.width * 0.65,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: 0}), id: `pocketing-pantry-product-${product.id}-top-bay-from`},
            {position: lib.object.sum(productPosition, {y: topBayHeight}), id: `pocketing-pantry-product-${product.id}-top-bay-to`}
          ]
        });

        dimensionSets.push({
          type: 'standalone',
          id: `pocketing-pantry-product-${product.id}-middle-bay-height`,
          alpha: Math.PI / 2,
          offset: -product.dimensions.width * 0.65,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: (product.dimensions.height - middleBayHeight) - bottomBayHeight}), id: `pocketing-pantry-product-${product.id}-middle-bay-from`},
            {position: lib.object.sum(productPosition, {y: product.dimensions.height - bottomBayHeight}), id: `pocketing-pantry-product-${product.id}-middle-bay-to`}
          ]
        });

        dimensionSets.push({
          type: 'standalone',
          id: `pocketing-pantry-product-${product.id}-bottom-bay-height`,
          alpha: Math.PI / 2,
          offset: -product.dimensions.width * 0.65,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: product.dimensions.height - bottomBayHeight}), id: `pocketing-pantry-product-${product.id}-bottom-bay-from`},
            {position: lib.object.sum(productPosition, {y: product.dimensions.height}), id: `pocketing-pantry-product-${product.id}-bottom-bay-to`},
          ]
        });
      }

      if ( //stacked wall products
        companyKey === 'hb'
        && _.includes([148, 149, 150, 151, 152, 153, 154, 155, 395, 396], product.productId)
      ) {
        var productPosition = Product.getPositionInElevation({product, elevation});
        var stackData = Product.getStackData({product});
        var topStackHeight = stackData.top.height;
        var bottomStackHeight = stackData.bottom.height;

        dimensionSets.push({
          type: 'standalone',
          id: `elevation-product-${product.id}-wall-unit-top-stack-height`,
          alpha: Math.PI / 2,
          offset: 0,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: 0, x: product.dimensions.width * 0.9}), id: `elevation-product-${product.id}-wall-unit-top-stack-height-from`},
            {position: lib.object.sum(productPosition, {y: topStackHeight, x: product.dimensions.width * 0.9}), id: `elevation-product-${product.id}-wall-unit-top-stack-height-to`}
          ]
        });

        dimensionSets.push({
          type: 'standalone',
          id: `elevation-product-${product.id}-wall-unit-bottom-stack-height`,
          alpha: Math.PI / 2,
          offset: 0,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: topStackHeight, x: product.dimensions.width * 0.9}), id: `elevation-product-${product.id}-wall-unit-bottom-stack-height-from`},
            {position: lib.object.sum(productPosition, {y: bottomStackHeight + topStackHeight, x: product.dimensions.width * 0.9}), id: `elevation-product-${product.id}-wall-unit-bottom-stack-height-to`}
          ]
        });
      }

      if (_.includes([384, 385], product.productId)) {//pivot doors
        var productPosition = Product.getPositionInElevation({product, elevation});

        dimensionSets.push({
          type: 'standalone',
          id: `elevation-product-${product.id}-pivot-door-overall-height`,
          alpha: Math.PI / 2,
          offset: 0,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(productPosition, {y: -1, x: product.dimensions.width * 0.6}), id: `elevation-product-${product.id}-pivot-door-height-from`},
            {position: lib.object.sum(productPosition, {y: product.dimensions.height + 3/4, x: product.dimensions.width * 0.6}), id: `elevation-product-${product.id}-pivot-door-height-to`}
          ]
        });
      }

      if (_.includes([1355, 901, 691], product.productId)) {//st open bays ie bookcases
        if (openUnitReplacesSubcounter) {
          var productPosition = Product.getPositionInElevation({product, elevation});

          dimensionSets.push({
            type: 'standalone',
            id: `elevation-product-${product.id}-open-unit-height`,
            alpha: Math.PI / 2,
            offset: -product.dimensions.width * 0.25,
            showLabelBorder: true,
            targets: [
              {position: lib.object.sum(productPosition, {y: -0.5}), id: `elevation-product-${product.id}-open-unit-height-from`},
              {position: lib.object.sum(productPosition, {y: product.dimensions.height}), id: `elevation-product-${product.id}-open-unit-height-to`}
            ]
          });
        }
      }
      else if (_.includes([762, 1516, 64], product.productId)) { //HINT: universal products i.e. microwave
        var openingHeight = _.get(product, 'appliancesData[0].dimensions.height');

        if (openingHeight) {
          var drawerHeight = product.dimensions.height - (openingHeight + 1.5) + (openUnitReplacesSubcounter ? (0.5 - 1/8) : 0);
          var productPosition = Product.getPositionInElevation({product, elevation});
          productPosition.y -=  (openUnitReplacesSubcounter ? 0.5 : 0);

          dimensionSets.push({
            type: 'standalone',
            id: `elevation-product-${product.id}-microwave-drawer-height`,
            alpha: Math.PI / 2,
            offset: -product.dimensions.width * 0.25,
            showLabelBorder: true,
            targets: [
              {position: lib.object.sum(productPosition, {y: product.dimensions.height - drawerHeight + (openUnitReplacesSubcounter ? 0.5 : 0)}), id: `elevation-product-${product.id}-microwave-drawer-height-from`},
              {position: lib.object.sum(productPosition, {y: product.dimensions.height + (openUnitReplacesSubcounter ? 0.5 : 0)}), id: `elevation-product-${product.id}-microwave-drawer-height-to`}
            ]
          });

          if (activeDetailLevel !== 'production') {
            if (product.productId !== 762) {
              dimensionSets.push({
                type: 'standalone',
                id: `elevation-product-${product.id}-microwave-unit-height`,
                alpha: Math.PI / 2,
                offset: -product.dimensions.width * 0.25,
                showLabelBorder: true,
                targets: [
                  {position: productPosition, id: `elevation-product-${product.id}-microwave-unit-height-from`},
                  {position: lib.object.sum(productPosition, {y: product.dimensions.height - drawerHeight + (openUnitReplacesSubcounter ? 0.5 : 0)}), id: `elevation-product-${product.id}-microwave-unit-height-to`}
                ]
              });
            }

            dimensionSets.push({
              type: 'standalone',
              id: `elevation-product-${product.id}-microwave-appliance-height`,
              alpha: Math.PI / 2,
              offset: -product.dimensions.width * 0.85,
              showLabelBorder: true,
              targets: [
                {position: lib.object.sum(productPosition, {y: 3/4 + (product.productId === 762 ? 1/8 : 0)}), id: `elevation-product-${product.id}-microwave-appliance-height-from`},
                {position: lib.object.sum(productPosition, {y: product.dimensions.height - drawerHeight - 3/4 + (openUnitReplacesSubcounter ? 0.5 : 0)}), id: `elevation-product-${product.id}-microwave-appliance-height-to`}
              ]
            });
          }
        }
      }
      else if (_.includes([162, 163, 164, 165, 166, 167, 406, 1150, 1159, 1160, 1322, 1379], product.productId)) { //HINT: shelfbank products
        var {bottomShelfOffset = 0} = product.customData;
        var {dimensions} = product;

        var shelfOffsets = Product.getShelfOffsets({product});

        var netOffset = 0;
        var x = dimensions.width * 0.66;

        _.forEach(shelfOffsets, (offset, i) => {
          //HINT offsetting the dim for the shelf itsself
          var shelfHeightOffset = 1.5;

          if (bottomShelfOffset && i === 0) shelfHeightOffset = 0;

          dimensionSets.push({
            alpha: Math.PI / 2,
            offset: 0,
            type: 'standalone',
            id: `elevation-product-${product.id}-${i}-shelfbank-standalone`,
            showLabelBorder: true,
            targets: [
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x, y: dimensions.height - netOffset - offset - shelfHeightOffset}), id: `elevation-product-${product.id}-${i}-shelfbank-standalone-from`},
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x, y: dimensions.height - netOffset - shelfHeightOffset}), id: `elevation-product-${product.id}-${i}-shelfbank-standalone-to`}
            ]
          });

          netOffset += offset + shelfHeightOffset;
        });

        if (netOffset !== dimensions.height - 1.5) {
          dimensionSets.push({
            alpha: Math.PI / 2,
            offset: 0,
            type: 'standalone',
            id: `elevation-product-${product.id}-standalone-shelfbank-bottom-shelf`,
            showLabelBorder: true,
            targets: [
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x, y: 0}), id: `elevation-product-${product.id}-standalone-shelfbank-bottom-shelf-from`},
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x, y: dimensions.height - netOffset - 1.5}), id: `elevation-product-${product.id}-standalone-shelfbank-bottom-shelf-to`}
            ]
          });
        }
      }
      else if (_.includes([
        42, //HB
        1359, //ST
      ], product.productId)) { //HINT: corner unit door widths that use 'doorAction'
        var {customData, details} = product;
        var {doorAction} = customData;

        dimensionSets.push({
          alpha: Math.PI,
          offset: 0,
          type: 'standalone',
          id: `corner-product-${product.id}-door-width-standalone`,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x: doorAction === 'left' ? 0 : product.dimensions.width - (product.dimensions.width - 18), y: 2 * product.dimensions.height / 3}), id: `corner-product-${product.id}-door-width-standalone-from`},
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x: doorAction === 'left' ? product.dimensions.width - 18 : product.dimensions.width, y: 2 * product.dimensions.height / 3}), id: `corner-product-${product.id}-door-width-standalone-to`}
          ]
        });
      }
      else if (_.includes(
        [
          43, 44, 179, 219, 220, 239, 669, 1414, 989, //HB
          905, 989, 940 //ST
        ], product.productId)) { //HINT: corner unit door widths that use 'orientation'
        var {orientation} = product.customData;

        var doorWidth = product.dimensions.width === 40 ? 21 : 18; //HINT: rule specific to product ids 43 and 44
        if (_.includes([179, 989], product.productId)) doorWidth = product.dimensions.width - 14;
        if (_.includes([219, 220, 239, 669, 1414, 940], product.productId)) doorWidth = product.dimensions.width - 18;

        dimensionSets.push({
          alpha: Math.PI,
          offset: 0,
          type: 'standalone',
          id: `corner-product-${product.id}-door-width-standalone`,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x: orientation === 'left' ? 0 : product.dimensions.width - doorWidth, y: 2 * product.dimensions.height / 3}), id: `corner-product-${product.id}-door-width-standalone-from`},
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation, isProjection, projectionY}), {x: orientation === 'left' ? doorWidth : product.dimensions.width, y: 2 * product.dimensions.height / 3}), id: `corner-product-${product.id}-door-width-standalone-to`}
          ]
        });
      }
      else if (_.includes([173], product.productId)) { //HINT: hood surround shelf height
        var {hoodShelfHeight} = product.customData;

        dimensionSets.push({
          alpha: Math.PI / 2,
          offset: 0,
          type: 'standalone',
          id: `hood-surround-${product.id}-shelf-spacing`,
          showLabelBorder: true,
          targets: [
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: product.dimensions.width / 3, y: product.dimensions.height - 0.75}), id: `hood-surround-${product.id}-shelf-spacing-from`},
            {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: product.dimensions.width / 3, y: product.dimensions.height - hoodShelfHeight - 0.75}), id: `hood-surround-${product.id}-shelf-spacing-to`}
          ]
        });
      }
      else if (_.includes(K[companyKey].ids.verticalHiddenPanels, product.productId)) { //HINT: vertical hidden panel
        // var positionInElevation = Product.getPositionInElevation({product, elevation});
        // var line = {
        //   from: lib.object.sum(positionInElevation, {y: product.dimensions.height * 0.7}),
        //   to: lib.object.sum(positionInElevation, {x: product.dimensions.depth, y: product.dimensions.height * 0.7})
        // };

        // dimensionSets.push({
        //   alpha: lib.trig.alpha({p1: line.from, p2: line.to}),
        //   offset: 0,
        //   type: 'standalone',
        //   id: `hidden-panel-${product.id}-width`,
        //   showLabelBorder: true,
        //   targets: [
        //     {position: line.from, id: `hidden-panel-${product.id}-width-from`},
        //     {position: line.to, id: `hidden-panel-${product.id}-width-to`},
        //   ]
        // });
      }
      else if (_.includes(K[companyKey].ids.horizontalHiddenPanels, product.productId)) { //HINT: horizontal hidden panel
        // dimensionSets.push({
        //   alpha: Math.PI / 2,
        //   offset: 0,
        //   type: 'standalone',
        //   id: `hidden-panel-${product.id}-height`,
        //   showLabelBorder: true,
        //   targets: [
        //     {position: Product.getPositionInElevation({product, elevation}), id: `hidden-panel-${product.id}-height-from`},
        //     {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {y: product.dimensions.depth}), id: `hidden-panel-${product.productId}-height-to`}
        //   ]
        // });
      }
      //HINT shelfbank frames
      else if (Product.getIsShelfbank({product})) {
        var origin = lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 0, y: product.dimensions.height});
        var shelves = _.filter(childProducts, product => _.includes(['woodShelf', 'steelShelf'], Product.getType({product})));

        if (shelves.length > 0) {
          var ys = _.orderBy(_.uniq([0, -product.dimensions.height, ..._.flatMap(shelves, shelf => {
            return [shelf.position.y, shelf.position.y - shelf.dimensions.height];
          })]));

          _.forEach(ys, (y, i) => {
            if (i < ys.length - 1) {
              var toY = ys[i + 1];

              //HINT don't dimension shelf heights
              if (Math.abs(y - toY) > 2.5) {
                var from = lib.object.sum(origin, {y, x: product.dimensions.width * 0.33});
                var to = lib.object.sum(origin, {y: toY, x: product.dimensions.width * 0.33});
                var dimId = `shelfbank-${product.id}-${i}-distance`;

                dimensionSets.push({
                  alpha: lib.trig.alpha({p2: from, p1: to}),
                  offset: 0,
                  type: 'standalone',
                  id: dimId,
                  showLabelBorder: true,
                  targets: [
                    {position: from, id: `${dimId}-from`},
                    {position: to, id: `${dimId}-to`}
                  ]
                });
              }
            }
          });
        }
      }
      else if (Product.getIsOpencasePanel({product}) || Product.getCanHavePegs({product})) {
        let positionInElevation = Product.getPositionInElevation({product, elevation});
        positionInElevation = _.omit(positionInElevation, ['z']);

        var {dimensions} = product;
        var origin = lib.object.sum(positionInElevation, {x: 0, y: product.dimensions.height});
        var addOriginLine = ({from, to, id, ...props}) => {
          from = lib.object.sum(origin, from);
          to = lib.object.sum(origin, to);

          dimensionSets.push({
            alpha: lib.trig.alpha({p2: from, p1: to}), offset: (props.fixedTransform || (props.alwaysOutside ? 10 : 0)),
            type: 'standalone', id, showLabelBorder: true,
            targets: [
              {position: from, id: `${id}-from`, hideDot: true},
              {position: to, id: `${id}-to`, hideDot: true}
            ]
          });
        };

        var containerYOffset = 0, isProjected = false;
        var {verticalThreshold, horizontalThreshold} = {verticalThreshold: 6, horizontalThreshold: -1};//CFGProduct.K.dimensionsLineThreshold;
        var relevantSiblings = _.filter(containers, container => {
          var isRelevant = container.type === 'opencase' && Container.getHasProjection({container, elevation});

          return isRelevant;
        });

        var minXPosition = _.min(_.map(relevantSiblings, container => {
          return Container.getPositionInElevation({container, elevation}).x;
        }));

        var maxXPosition = _.max(_.map(relevantSiblings, container => {
          return Container.getPositionInElevation({container, elevation}).x + container.dimensions.width;
        }));

        if (showProjections && Product.getIsAnyOpencasePanel({product})) {
          isProjected = true;
          var {projectionY} = Container.getProjectionData({container, elevation});

          containerYOffset = projectionY + container.position.y + container.dimensions.height;
        }

        var containerXOrigin = containerData.wallprint[0].x;

        var xInElevation = containerXOrigin + product.position.x + (product.dimensions.width / 2);
        var elevationXRange = Elevation.getXRange({elevation});
        var isPulledRight = Math.abs(xInElevation - elevationXRange.to) < Math.abs(xInElevation - elevationXRange.from);

        var xDimY = isProjected ? containerYOffset : -dimensions.height + verticalThreshold;

        var isStandalone = isProjected ? false : true;

        //HINT when not projected automated standard width/height dims are sufficient
        if (isProjected) {
          // x dim
          addOriginLine({
            to: {x: 0, y: xDimY},
            from: {x: dimensions.width, y: xDimY},
            isStandalone,
            id: `${product.id}-opencase-x${isProjected ? '-projected' : ''}`,
            fixedTransform: isProjected ? 20 : undefined
          });

          var yDimX = isProjected ? ((isPulledRight ? maxXPosition : minXPosition) - origin.x) : (dimensions.width - verticalThreshold);
          // y dim

          addOriginLine({
            [isPulledRight ? 'from' : 'to']: {
              x: yDimX,
              y: isProjected ? (-dimensions.height + containerYOffset) : (-dimensions.height)
            },
            [isPulledRight ? 'to' : 'from']: {
              x: yDimX,
              y: isProjected ? (containerYOffset) : (0)
            },
            isStandalone,
            alwaysOutside: isProjected ? true : false,
            id: `${product.id}-opencase-y${isProjected ? '-projected' : ''}`,
            fixedTransform: isProjected ? 20 : undefined
          });
        }

        var componentPositions = Opencase.getActiveFittingPositionsInPanel({product});

        if (Product.getCanHavePegs({product}) && !Product.getIsAnyOpencasePanel({product})) {
          componentPositions = _.map(childProducts, ({position, dimensions}) => {
            return lib.object.sum(position, {x: dimensions.width / 2, y: -dimensions.height / 2});
          });
        }

        // components dims
        var componentXPositions = lib.sort(_.uniq(_.map(componentPositions, 'x')));

        var x1 = 0;
        // horizontal dims
        var relevantComponentPositions = _.sortBy(_.uniqBy(componentPositions, 'x'), ['x']);

        var yPositionFor = ({position}) => {
          return isProjected ? (containerYOffset + position.y) : position.y + containerYOffset;
        };

        //opencase components
        _.forEach(relevantComponentPositions, (position, i) => {
          addOriginLine({
            to: {
              x: x1,
              y: yPositionFor({position})
            },
            from: {
              x: position.x,
              y: yPositionFor({position})
            },
            fixedTransform: (Product.getCanHavePegs({product}) && !Product.getIsAnyOpencasePanel({product})) ? 5 : undefined,
            alwaysOutside: isProjected ? true : false,
            isStandalone: isProjected ? false : true,
            tinyFont: true,
            id: `opencase-fittings-${product.id}-${i}${isProjected ? '-projected' : ''}`
          });

          x1 = position.x;
        });

        // vertical dims
        var y1 = 0;
        var relevantComponentPositions = _.uniqBy(_.orderBy(componentPositions, ['y', 'x'], ['desc', 'asc']), 'y');

        _.forEach(relevantComponentPositions, (position, i) => {
          var yDimX = position.x;

          if (isProjected) {
            yDimX = (isPulledRight ? maxXPosition : minXPosition) - origin.x;
          }

          addOriginLine({
            [isPulledRight ? 'from' : 'to']: {
              x: yDimX,
              y: position.y + containerYOffset
            },
            [isPulledRight ? 'to' : 'from']: {
              x: yDimX,
              y: y1 + containerYOffset
            },
            isStandalone: false,
            alwaysOutside: isProjected ? true : false,
            id: `opencase-component-${product.id}-${i}${isProjected ? '-projected' : ''}`
          });

          y1 = position.y;
        });
      }
      else if (Product.getType({product}) === 'horizontalBarblock') {
        let positionInElevation = Product.getPositionInElevation({product, elevation});
        positionInElevation = _.omit(positionInElevation, ['z']);

        var {dimensions} = product;
        var origin = lib.object.sum(positionInElevation, {x: 0, y: dimensions.height});
        var addOriginLine = ({from, to, id, offset, ...props}) => {
          from = lib.object.sum(origin, from);
          to = lib.object.sum(origin, to);

          dimensionSets.push({
            alpha: lib.trig.alpha({p2: from, p1: to}), offset,
            type: 'standalone', id, showLabelBorder: true,
            targets: [
              {position: from, id: `${id}-from`},
              {position: to, id: `${id}-to`}
            ]
          });
        };

        var componentPositions = _.map(childProducts, 'position');

        _.forEach(childProducts, childProduct => {
          var xInset = product.position.x + Barblock.getWrapInset({product: childProduct, parentProduct: product}).x;

          addOriginLine({
            from: {x: childProduct.position.x + xInset, y: -dimensions.height},
            to: {x: childProduct.position.x + childProduct.dimensions.width + xInset, y: -dimensions.height},
            offset: 2.5,
            id: `horizontalBarblock-${product.id}-${childProduct.id}`
          });
        });
      }
      else if (activeDetailLevel === 'production') {
        //HINT SCULPTED PANELS
        if (_.includes(K.hb.ids.sculptedPanels, product.productId)) {
          var {topRailHeight, bottomCasingHeight} = product.customData;

          if (!topRailHeight && topRailHeight !== 0) topRailHeight = 5;
          if (!bottomCasingHeight && bottomCasingHeight !== 0) bottomCasingHeight = 5;

          dimensionSets.push({
            alpha: Math.PI / 2,
            offset: 0,
            type: 'standalone',
            id: `sculpted-panel-product-${product.id}-detailed-height-standalone`,
            showLabelBorder: true,
            targets: [
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 2 * product.dimensions.width / 3, y: 0}), id: `sculpted-panel-product-${product.id}-bottom-casing-height-standalone-from`},
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 2 * product.dimensions.width / 3, y: topRailHeight}), id: `sculpted-panel-product-${product.id}-bottom-casing-height-standalone-to`},
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 2 * product.dimensions.width / 3, y: product.dimensions.height - bottomCasingHeight}), id: `sculpted-panel-product-${product.id}-top-rail-height-standalone-from`},
              {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 2 * product.dimensions.width / 3, y: product.dimensions.height}), id: `sculpted-panel-product-${product.id}-top-rail-height-standalone-to`},
            ]
          });

          if (_.includes(K.hb.ids.sculptedEndPanels, product.productId)) {
            var stileWidth = product.dimensions.depth;

            dimensionSets.push({
              alpha: Math.PI,
              offset: 0,
              type: 'standalone',
              id: `sculpted-panel-product-${product.id}-detailed-width-standalone`,
              showLabelBorder: true,
              targets: [
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: 0, y: product.dimensions.height / 3}), id: `sculpted-panel-product-${product.id}-left-stile-width-standalone-from`},
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: stileWidth, y: product.dimensions.height / 3}), id: `sculpted-panel-product-${product.id}-left-stile-width-standalone-to`},
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: product.dimensions.width - stileWidth, y: product.dimensions.height / 3}), id: `sculpted-panel-product-${product.id}-right-stile-width-standalone-from`},
                {position: lib.object.sum(Product.getPositionInElevation({product, elevation}), {x: product.dimensions.width, y: product.dimensions.height / 3}), id: `sculpted-panel-product-${product.id}-right-stile-width-standalone-to`},
              ]
            });
          }
        }


      }

      var isOpencaseComponent = Product.getIsOpencaseComponent({product});
      var isBarblockComponent = Product.getIsBarblockComponent({product});
      var isShelfbankComponent = Product.getIsShelfbankComponent({product});
      var isFloatingShelf = _.includes([1495, 1496, 1497, 1498, 1186], product.productId);
      var isApplianceStackFrame = Product.getIsApplianceStackFrame({product});
      var isPeg = Product.getIsPeg({product});

      if (container && !isPeg && !isOpencaseComponent && !isBarblockComponent && !isShelfbankComponent && !isFloatingShelf && !isApplianceStackFrame) {
        // lines.push(...ElevationDimensions.getProductDimensionsLines({product, elevation, container, parentProduct, childProducts, mode, showProjections, activeDetailLevel}));
        var isProjection = false; //TODO
        var projectionY = 0; //TODO
        var wallprintLines = _.mapValues(Product.getWallprintLines({product, elevation, isProjection, projectionY}), line => {
          return {from: line.to, to: line.from};
        });

        _.forEach({
          width: {lines: [wallprintLines.bottom, wallprintLines.top], standaloneLine: wallprintLines.top, standaloneOffset: 7, idPrefix: `elevation-product-${product.id}-width`},
          height: {lines: [wallprintLines.left, wallprintLines.right], standaloneLine: wallprintLines.left, standaloneOffset: (containerData.trappedDataBySide.left.isTrapped && xInContainer < 5 ? (5 - xInContainer) : 0) + 7, idPrefix: `elevation-product-${product.id}-height`},
        }, ({lines, standaloneLine, standaloneOffset, idPrefix}, sizeKey) => {
          var axisKey = sizeKey === 'width' ? 'x' : 'y';
          var isBayProductWidthDim = sizeKey === 'width' && productType.bayCount && productType.bayCount > 1;
          var isAccountedFor = _.some(accountedForDimensionLinesBySizeKey[sizeKey], accountedLine => {
            var accountedValues = _.map(accountedLine, axisKey);

            return _.includes(accountedValues, standaloneLine.from[axisKey]) && _.includes(accountedValues, standaloneLine.to[axisKey]);
          });

          //HINT
          //only want to use isAccountedFor when we are drawing the dim standalone
          // if (!isAccountedFor) {
          //HINT get "trapped" to only consider products in the same container
          var filterEntities = (entity) => {
            if (!entity.product) return false;

            var entityParentProduct = _.find(products, {id: entity.product.productInstanceId});
            var entityContainer = _.find(containers, {id: entity.product.containerInstanceId || entityParentProduct?.containerInstanceId});

            return entityContainer && entityContainer.id === container.id;
          };

          var untrappedLines = _.filter(lines, (line, i) => {
            var lineSideKey = sizeKey === 'width' ? ['bottom', 'top'][i] : ['left', 'right'][i];

            return !containerData.trappedDataBySide[lineSideKey].isTrapped && !getIsTrapped({line, entityType: 'product', entityId: product.id}, {filterEntities, excludeEquivalentRanges: true});
          });

          accountedForDimensionLinesBySizeKey[sizeKey].push(standaloneLine);

          if (untrappedLines.length > 0) {
            //HINT draw bay dimensions
            if (isBayProductWidthDim) {
              var netBayWidth = 0;

              _.forEach(Product.getBayWidths({product}), (bayWidth, i) => {

                var bayUntrappedLines = [];

                _.forEach(untrappedLines, untrappedLine => {
                  if (untrappedLine.from.x > untrappedLine.to.x) {
                    bayUntrappedLines.push({
                      from: lib.object.difference(untrappedLine.from, {x: netBayWidth}),
                      to: lib.object.difference(untrappedLine.from, {x: netBayWidth + bayWidth})
                    });
                  }
                  else {
                    bayUntrappedLines.push({
                      from: lib.object.sum(untrappedLine.from, {x: netBayWidth}),
                      to: lib.object.sum(untrappedLine.from, {x: netBayWidth + bayWidth})
                    });
                  }
                });

                addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines: bayUntrappedLines, idPrefix: `${idPrefix}-bay-${i}`});

                netBayWidth += bayWidth;
              });

              //HINT draw standalone dimension for bay unit
              dimensionSets.push({
                alpha: lib.trig.alpha({p1: standaloneLine.from, p2: standaloneLine.to}),
                //HINT offset is larger than normal to pull above sink faucets on vanities
                offset: 9,
                type: 'standalone',
                id: `${idPrefix}-standalone`,
                showLabelBorder: true,
                targets: [
                  {position: standaloneLine.from, id: `${idPrefix}-from`},
                  {position: standaloneLine.to, id: `${idPrefix}-to`}
                ]
              });
            }
            else {
              addLineToNearestSide({allPoints, outsideDimensionSets, candidateLines: untrappedLines, idPrefix});
            }
          }
          else if (!isAccountedFor && (product.dimensions[sizeKey] !== containerData.dropzoneSize[sizeKey] || isBayProductWidthDim)) {
            var isTrappedLocally = getIsTrapped({line: standaloneLine, entityType: 'product', entityId: product.id}, {excludeEquivalentRanges: true, locally: true});

            if (_.includes(K[companyKey].ids.horizontalHiddenPanels, product.productId)) standaloneOffset = sizeKey === 'height' ? 0 : 3;

            if (isBayProductWidthDim) {
              var netBayWidth = 0;
              var bayStandaloneLine = wallprintLines.bottom;

              _.forEach(Product.getBayWidths({product}), (bayWidth, i) => {
                dimensionSets.push({
                  alpha: lib.trig.alpha({p1: bayStandaloneLine.from, p2: bayStandaloneLine.to}),
                  offset: standaloneOffset * (isTrappedLocally ? -1 : 1),
                  type: 'standalone',
                  id: `${idPrefix}-bay-${i}-standalone`,
                  showLabelBorder: true,
                  targets: [
                    {position: lib.object.sum(bayStandaloneLine.from, {x: netBayWidth}), id: `${idPrefix}-bay-${i}-from`},
                    {position: lib.object.sum(bayStandaloneLine.from, {x: netBayWidth + bayWidth}), id: `${idPrefix}-bay-${i}-to`}
                  ]
                });

                netBayWidth += bayWidth;
              });
            }

            if (product.dimensions[sizeKey] !== containerData.dropzoneSize[sizeKey]) {
              dimensionSets.push({
                alpha: lib.trig.alpha({p1: standaloneLine.from, p2: standaloneLine.to}),
                offset: standaloneOffset * (isTrappedLocally ? -1 : 1),
                type: 'standalone',
                id: `${idPrefix}-standalone`,
                showLabelBorder: true,
                targets: [
                  {position: standaloneLine.from, id: `${idPrefix}-from`},
                  {position: standaloneLine.to, id: `${idPrefix}-to`}
                ]
              });
            }
          }
          // }
        });
      }
    });
  }
}
