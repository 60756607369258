import React, {createRef} from 'react';
import _ from 'lodash';
import K from 'k';

import loadProjectData from 'helpers/load-project-data';
import PriceElement from './header/price-element';
import RoomSelectorElement from './header/room-selector-element';
import ConfiguratorGraphic from './components/configurator-graphic';
import ConfiguratorPropertyField from './components/configurator-property-field';
import ConfiguratorSelectorPropertyField from './components/configurator-selector-property-field';
import ConfiguratorOptionSelector from './components/configurator-option-selector';
import CanvasRooms3D from 'project-components/canvas-rooms-3d';
import Konva from 'konva';

import applyArchetypeParameterSelection from 'helpers/apply-archetype-parameter-selection';
import Room from 'project-helpers/room';
import Elevation from 'project-helpers/elevation';
import upArrowIcon from 'assets/up-arrow-black.png';

import { connect } from 'redux/index.js';
import { resourceActions } from 'redux/index.js';
import withUseParams from 'hooks/with-use-params';
import Popup from 'components/popup';

class ConfiguratorPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      elevationGraphicContainerSize: {width: 0, height: 0},
      roomGraphicContainerSize: {width: 0, height: 0},
      isShowingBuyPopup: false,
      isConfirming: false,
      hasConfirmed: false,
      activePropertyData: null,
      activePropertyKey: null,
      appliedOption: null,
      extraCanvasViewMode: 'threeD'
    };
  }

  styles = {
    button: {
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      borderRadius: 20,
      ...K.fonts.label,
      padding: K.spacing,
      opacity: 0.9,
      cursor: 'pointer'
    }
  };

  async componentDidMount() {
    var {match} = this.props; //TODO convert match to params
    Konva.pixelRatio = 2;

    if (window.location.hash) {
      global.apiToken = _.trimStart(window.location.hash, '#');
    }

    await loadProjectData({match, resourceActionDispatchers: this.props});

    setTimeout(async () => {
      var activeRoomId = _.sortBy(_.get(this.roomsByFloor[0], 'rooms'), 'rank')[0].id;
      this.setState({activeRoomId, activeElevationId: _.get(Room.get('sortedElevations', {roomId: activeRoomId}), '[0].id')});

      setTimeout(() => {
        var {project} = this.props;
        var projectTitle = `${project.title || `${project.companyKey === 'vp' ? 'ST' : 'HB'} for ${project.clientName}`}`;

        document.title = `${projectTitle} - ${project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'} Configurator`;

        if (project.companyKey === 'vp') {
          var link = document.querySelector('link[rel~=\'icon\']');
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
          }
          link.href = 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/st-favicon.ico';
        }

        this.setState({isLoaded: true});

        setTimeout(() => {
          if (this.elevationGraphicRef) {
            var containerBoundingRect = this.elevationGraphicRef.getBoundingClientRect();

            var elevationGraphicContainerSize = {
              width: containerBoundingRect.right - containerBoundingRect.left,
              height: containerBoundingRect.bottom - containerBoundingRect.top
            };

            this.setState({elevationGraphicContainerSize});
          }

          if (this.roomGraphicRef) {
            var containerBoundingRect = this.roomGraphicRef.getBoundingClientRect();

            var roomGraphicContainerSize = {
              width: containerBoundingRect.right - containerBoundingRect.left,
              height: containerBoundingRect.bottom - containerBoundingRect.top
            };

            this.setState({roomGraphicContainerSize});
          }
        });
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.elevationGraphicRef) {
      var containerBoundingRect = this.elevationGraphicRef.getBoundingClientRect();

      var elevationGraphicContainerSize = {
        width: containerBoundingRect.right - containerBoundingRect.left,
        height: containerBoundingRect.bottom - containerBoundingRect.top
      };

      if (!_.isEqual(prevState.elevationGraphicContainerSize, elevationGraphicContainerSize)) {
        this.setState({elevationGraphicContainerSize});
      }
    }
    if (this.roomGraphicRef) {
      var containerBoundingRect = this.roomGraphicRef.getBoundingClientRect();

      var roomGraphicContainerSize = {
        width: containerBoundingRect.right - containerBoundingRect.left,
        height: containerBoundingRect.bottom - containerBoundingRect.top
      };

      if (!_.isEqual(prevState.roomGraphicContainerSize, roomGraphicContainerSize)) {
        this.setState({roomGraphicContainerSize});
      }
    }
  }

  handlePropertyFieldChange = async ({id, value, path, activePropertyData, appliedOption}) => {
    var room = _.find(this.props.rooms, {id: this.state.activeRoomId});

    await applyArchetypeParameterSelection({
      room,
      archetypeId: _.get(room, 'archetypeId'),
      parameter: _.find(this.roomArchetypeParameters, {id}),
      value, path,
      resourceActions: this.props
    });

    this.setState({activePropertyKey: id, activePropertyData, appliedOption});
  };

  handleBuyPopupOnClose = () => this.setState({isShowingBuyPopup: false});
  handleBuyButtonClick = () => this.setState({isShowingBuyPopup: true});

  handleConfirmClick = async () => {
    var {project: {id: projectId, versionId}} = this.props;

    try {
      this.setState({isConfirming: true});
      var apiResponse = await lib.api.request({uri: 'de-project/handle-configurator-draft-submission', body: {projectId, versionId}});

      this.handleBuyPopupOnClose();

      this.setState({isConfirming: false, hasConfirmed: true});

      if (_.get(apiResponse, 'data.locked')) {
        await this.props.updateProject({id: projectId, props: {locked}, hitApi: false});
      }
    }
    catch (err) {
      this.handleBuyPopupOnClose();

      this.setState({isConfirming: false});
    }
  };
  handleSelectorPropertyFieldOnClick = ({activePropertyKey, activePropertyData, appliedOption}) => {
    if (this.state.activePropertyKey === activePropertyKey) {
      this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null});
    }
    else {
      this.setState({activePropertyKey, activePropertyData, appliedOption});
    }
  };

  handleOptionSelectorOnBackClick = () => {
    this.setState({activePropertyKey: null, activePropertyData: null, appliedOption: null});
  };

  get roomsByFloor() {
    var {floors, rooms} = this.props;

    return _.map(floors, (floor) => {
      return {
        ...floor,
        rooms: _.filter(rooms, {floorId: floor.id})
      };
    });
  }

  get roomArchetypeParameters() {
    var room = _.find(this.props.rooms, {id: this.state.activeRoomId});
    var roomParameters = _.flatMap(_.get(room, `parameters.${room.archetypeId}`, []));

    return roomParameters;
  }

  get archetypeParameterGroups() {
    var archetypeParameterGroups = [];

    _.forEach(this.roomArchetypeParameters, (parameter) => {
      var parameterGroup = _.find(archetypeParameterGroups, {key: parameter.type});

      var TypeToTitleMap = {
        material: 'Select your Materials',
        pull: 'Select your Pulls',
        conditionalResources: 'Other Options',
      };

      if (!parameterGroup) {
        archetypeParameterGroups.push({
          key: parameter.type,
          title: TypeToTitleMap[parameter.type],
          parameters: [parameter]
        });
      }
      else {
        parameterGroup.parameters.push(parameter);
      }
    });

    //HINT manually sort parameter groups by type
    archetypeParameterGroups = _.sortBy(archetypeParameterGroups, ({key}) => _.indexOf([
      'material', 'pull', 'conditionalResources'
    ], key));

    return archetypeParameterGroups;
  }

  getElevationGraphicRef = (ref) => this.elevationGraphicRef = ref;
  getRoomGraphicRef = (ref) => this.roomGraphicRef = ref;

  render() {
    var {project, rooms, floors, pulls, elevations} = this.props;
    var {isLoaded, isConfirming, hasConfirmed, elevationGraphicContainerSize, roomGraphicContainerSize, extraCanvasViewMode, activePropertyData, activePropertyKey, appliedOption} = this.state;

    var threeDViewOffset = {x: 0, y: 0};

    if (isLoaded) {
      var room = _.find(rooms, {id: this.state.activeRoomId});

      const points = room.plan.points;
      const xs = _.map(points, 'x'), ys = _.map(points, 'y');
      const minX = _.min(xs), minY = _.min(ys);
      const maxX = _.max(xs), maxY = _.max(ys);
      const size = {width: maxX - minX, height: maxY - minY};

      threeDViewOffset = lib.object.difference({x: -minX - size.width * 0.5, y: -minY - size.height * 0.5});

      var archetype = _.find(this.props.archetypes, {id: room.archetypeId});
    }
    var style = {
      header: {
        column: {flex: 1, display: 'flex', flexDirection: 'column'}
      },
      fonts: {
        label: {fontWeight: 500, fontSize: '1em', letterSpacing: '0.1em'}
      },
    };

    return (
      <div style={{height: '100%', width: '100%'}} className='cfg-configurator-page'>
        {!isLoaded ? (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{...style.fonts.label, textTransform: 'uppercase', marginBottom: '0.5rem'}}>
              Loading
            </div>
            <div style={{width: '2rem', height: '2rem'}} className="show-loader loader-dark"></div>
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
            <div className='content' style={{position: 'relative', display: 'flex', flex: 1, height: '100%', maxWidth: '100%', minWidth: 0, minHeight: 0, flexDirection: 'row'}}>

            {
              this.state.activePropertyKey && _.size(this.state.activePropertyData) ? (
                <ConfiguratorOptionSelector {...{activePropertyKey, activePropertyData, appliedOption, archetype, project, isConfirming, hasConfirmed, handleBuyButtonClick: this.handleBuyButtonClick, pulls}} onBackClick={this.handleOptionSelectorOnBackClick} onApplyClick={this.handlePropertyFieldChange}/>
              ) : (
                <>
                  <div style={{display: 'flex', width: '50%', height: '100%', flexDirection: 'column', padding: '2rem 0rem 2rem 2.5rem', overflowY: 'scroll', borderRight: 'rgba(0, 0, 0, 0.05) 1px solid'}}>
                    <div style={{...style.fonts.label, ...style.header.column, marginBottom: '7rem'}}>
                      <div style={{letterSpacing: '0.15em', fontSize: '1.25em', fontWeight: 400, textTransform: 'uppercase', opacity: 0.5}}>
                        {project.companyKey === 'vp' ? 'Space Theory' : 'Henrybuilt'}{archetype && archetype.type === 'atlanticFields' ? ' & Atlantic Fields' : ''}
                      </div>
                      <div style={{marginTop: '0.5rem', marginBottom: '1.5rem', fontSize: '1.5em', letterSpacing: '0.06em'}}>
                        {project.title || `For ${project.clientName}`}{!!project.locked && ' - Finalized By Client'}
                      </div>
                      {!project.locked && !this.state.isConfirming && !this.state.hasConfirmed && <div><div style={{width: 'fit-content', fontWeight: 500, textTransform: 'uppercase', letterSpacing: '0.06em', fontSize: '0.85em', cursor: 'pointer', backgroundColor: 'black', color: 'white', padding: '0.5rem 1rem 0.5rem'}} onClick={this.handleBuyButtonClick}>FINALIZE</div></div>}
                    </div>
                    <div style={{display: 'flex', gap: '3rem', flexGrow: 1, minHeight: 0, flexDirection: 'column'}}>
                      {/* <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', width: '100%'}}> */}
                        {_.map(this.archetypeParameterGroups, ({key, title, parameters}) => {
                          return (
                            <div key={key}>
                              <div style={{...style.fonts.label, width: '33%', letterSpacing: '0.09em', textTransform: 'uppercase', marginBottom: '2rem', paddingLeft: '70px'}}>{title}</div>
                              {_.map(parameters, (parameter, index) =>{
                                var {type} = parameter;

                                return (
                                  <ConfiguratorSelectorPropertyField
                                    isEditable={((!project.locked || project.isEmployee) && !this.state.isConfirming && !this.state.hasConfirmed)}
                                    key={`configurator-selector-property-field-${index}-${parameter.id}`}
                                    handlePropertyFieldChange={({id, value, path}) => this.handlePropertyFieldChange({id, value, path})}
                                    handleClick={this.handleSelectorPropertyFieldOnClick}
                                    {...{parameter, room, index, activePropertyKey: this.state.activePropertyKey, project, archetypeParameterGroup: {key, title}}}
                                  />
                                );
                              })}
                            </div>
                          );
                        })}
                      {/* </div> */}
                    </div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', flexGrow: 1, flexDirection: 'column', height: '100%', padding: '2rem 0rem 2rem 2.5rem', zIndex: 1}}>
                    <div style={{...style.fonts.label, marginBottom: '0.5rem'}}>
                      <RoomSelectorElement {...{
                        rooms, floors, elevations,
                        roomsByFloor: this.roomsByFloor,
                        activeRoomId: this.state.activeRoomId,
                        activeElevationId: this.state.activeElevationId,
                        setActiveRoomId: ({roomId}) => {
                          var sortedElevations = Room.get('sortedElevations', {roomId});

                          this.setState({activeRoomId: roomId, activeElevationId: _.get(sortedElevations, '[0].id')});
                        },
                        setActiveElevationId: ({elevationId}) => this.setState({activeElevationId: elevationId})
                      }}/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%', width: '60%', marginBottom: '2rem'}} ref={this.getElevationGraphicRef}>
                      {!(elevationGraphicContainerSize.width === 0 && elevationGraphicContainerSize.height === 0) && this.state.activeElevationId && (
                        <ConfiguratorGraphic
                          type={'elevation'}
                          {...{containerSize: elevationGraphicContainerSize, model: _.find(this.props.elevations, {id: this.state.activeElevationId}), project, visibilityLayers: {wallsAndArchElements: true}, activeDimensionsLayer: 'client', activeProjectGraphicsLayer: 'renderings', detailLevel: 'rendering', fillMode: 'materialColors'}}
                        />
                      )}
                    </div>
                    <div style={{zIndex: 2, width: '50%', height: '40%'}} >
                      <div style={{width: '100%', height: '100%'}} ref={this.getRoomGraphicRef}>
                        {extraCanvasViewMode === 'threeD' ? (
                          <CanvasRooms3D
                            isArchetype={false}
                            cameraZoom={1}
                            {...{showWallsAndArchElements: true, viewOffset: threeDViewOffset, room, activeDetailLevel: 'fullDetail', activeFillMode: 'materialColors'}}
                          />
                        ) : (
                          <ConfiguratorGraphic
                            type={'room'}
                            {...{containerSize: roomGraphicContainerSize, model: room, project, visibilityLayers: {wallsAndArchElements: true}, activeDimensionsLayer: 'client', activeProjectGraphicsLayer: 'renderings', detailLevel: 'rendering', fillMode: 'materialColors'}}
                          />
                        )}
                      </div>
                      <div style={{textAlign: 'center', fontSize: '0.85em', letterSpacing: '0.075em', opacity: 0.5, cursor: 'pointer'}} onClick={() => this.setState({extraCanvasViewMode: extraCanvasViewMode === 'threeD' ? 'room' : 'threeD'})}>VIEW {extraCanvasViewMode === 'threeD' ? 'PLAN' : '3D'} DRAWING</div>
                    </div>
                  </div>
                </>
                )
              }
            </div>
            {!project.locked && this.state.isShowingBuyPopup && (
              <Popup onClose={this.handleBuyPopupOnClose}>
                <div style={{backgroundColor: 'white'}}>
                  <p>Are you sure? Clicking "confirm" notifies our team and locks your selections, you won't be able to make edits in the future.</p>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button onClick={this.handleBuyPopupOnClose} style={{...this.styles.button}}>Cancel</button>
                    <button disabled={this.state.isConfirming} onClick={this.handleConfirmClick} style={{...this.styles.button, marginLeft: 15, opacity: this.state.isConfirming ? 0.5 : 0.9}}>Confirm</button>
                  </div>
                </div>
              </Popup>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect({
  mapState: state => {
    return {
      projects: state.resources.projects.byId,
      floors: state.resources.floors.byId,
      rooms: state.resources.rooms.byId,
      elevations: state.resources.elevations.byId,
      scopes: state.resources.scopes.byId,
      containerTypes: state.resources.containerTypes.byId,
      products: state.resources.products.byId,
      productTypes: state.resources.productTypes.byId,
      project: _.values(state.resources.projects.byId)[0],
      materialTypes: state.resources.materialTypes.byId,
      materials: state.resources.materials.byId,
      materialClasses: state.resources.materialClasses.byId,
      pulls: state.resources.pulls.byId,
      archetypes: state.resources.archetypes.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['trackProjects', 'updateProject']),
    ..._.pick(resourceActions.floors, ['trackFloors']),
    ..._.pick(resourceActions.rooms, ['trackRooms', 'updateRoom', 'modifyRooms']),
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope', 'updateScopes']),
    ..._.pick(resourceActions.walls, ['trackWalls', 'updateWall', 'modifyWalls']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainer', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ..._.pick(resourceActions.containerTypes, ['trackContainerTypes']),
    ..._.pick(resourceActions.elevations, ['trackElevations', 'updateElevations', 'updateElevation', 'createElevations', 'destroyElevations', 'modifyElevations']),
    ..._.pick(resourceActions.archElements, ['trackArchElements', 'updateArchElement', 'destroyArchElement', 'destroyArchElements']),
    ..._.pick(resourceActions.products, ['trackProducts', 'createProduct', 'createProducts', 'updateProduct', 'updateProducts', 'destroyProduct', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productTypes, ['trackProductTypes']),
    ..._.pick(resourceActions.models, ['trackModels']),
    ..._.pick(resourceActions.appliances, ['trackAppliances']),
    ..._.pick(resourceActions.pulls, ['trackPulls']),
    ..._.pick(resourceActions.materialClasses, ['trackMaterialClasses']),
    ..._.pick(resourceActions.materialTypes, ['trackMaterialTypes']),
    ..._.pick(resourceActions.materials, ['trackMaterials']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions', 'createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
    ..._.pick(resourceActions.pricingRules, ['trackPricingRules']),
    ..._.pick(resourceActions.productOptionTypes, ['trackProductOptionTypes']),
    ..._.pick(resourceActions.projectGraphics, ['trackProjectGraphics', 'updateProjectGraphic', 'updateProjectGraphics', 'destroyProjectGraphic', 'destroyProjectGraphics']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.archetypes, ['trackArchetypes']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'updateVolume', 'updateVolumes', 'destroyVolume', 'destroyVolumes', 'modifyVolumes']),
    ..._.pick(resourceActions.parts, ['trackParts', 'updateParts'])
  }
})(withUseParams(ConfiguratorPage));